import globalAxios from 'axios'
import { StatusCodes } from 'http-status-codes'

import type { AxiosRetryConfig } from '@ngb-frontend/shared/types'
import type { AxiosError } from 'axios'

export const defaultRetryConfig: AxiosRetryConfig = {
  retry: 3,
  retryDelay: 1000,
}

const skipRetryErrors = [
  StatusCodes.METHOD_NOT_ALLOWED,
  StatusCodes.FAILED_DEPENDENCY,
  StatusCodes.NOT_FOUND,
  StatusCodes.NO_CONTENT,
]

export const getRetryAxios = (retryConfig = defaultRetryConfig) => {
  const retryAxios = globalAxios.create({ ...retryConfig })

  retryAxios.interceptors.response.use(undefined, (err: AxiosError) => {
    const config = err.config as AxiosRetryConfig
    const status = err.response?.status || StatusCodes.INTERNAL_SERVER_ERROR
    if (!config.retry || skipRetryErrors.includes(status)) {
      return Promise.reject(err)
    }

    config.retry -= 1
    const delayRetryRequest = new Promise((resolve) => {
      setTimeout(() => resolve(undefined), config.retryDelay)
    })

    return delayRetryRequest.then(() => retryAxios(config))
  })

  return retryAxios
}
