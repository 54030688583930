import { type FormApi, type Unsubscribe } from 'final-form'

type FormInput = HTMLInputElement | HTMLTextAreaElement

type FormErrors = Record<string, unknown>

export const scrollToErrorClass = 'form-field-error'

export const createScrollToErrorsDecorator =
  <TProps>() =>
  (formApi: FormApi<TProps, Partial<TProps>>): Unsubscribe => {
    const focusOnFirstError = async () => {
      const el = document.querySelector(`.${scrollToErrorClass}`)
      const input = el?.querySelector<FormInput>('input, textarea')

      el?.scrollIntoView({
        block: 'center',
      })

      input?.focus?.()
    }

    // Save original submit function
    const originalSubmit = formApi.submit

    // Subscribe to errors, and keep a local copy of them
    let state: { errors?: FormErrors; submitErrors?: FormErrors } = {}
    const unsubscribe = formApi.subscribe(
      (nextState) => {
        state = nextState
      },
      { errors: true, submitErrors: true },
    )

    // What to do after submit
    const afterSubmit = () => {
      const { errors = {}, submitErrors = {} } = state

      if (
        Object.values(errors).some(Boolean) ||
        Object.values(submitErrors).some(Boolean)
      ) {
        focusOnFirstError()
      }
    }

    // Rewrite submit function
    formApi.submit = () => {
      const result = originalSubmit.call(formApi)

      typeof result?.then === 'function'
        ? result.then(afterSubmit)
        : afterSubmit()

      return result
    }

    return () => {
      unsubscribe()
      formApi.submit = originalSubmit
    }
  }
