import { InteractionRequiredAuthError } from '@azure/msal-browser'
import axios from 'axios'
import { StatusCodes } from 'http-status-codes'
import { v4 as uuidv4 } from 'uuid'

import { getRetryAxios } from '../axios/getRetryAxios'
import { RequestError } from '../error/RequestError'

import type { setupMSAL } from '../auth/setupMSAL'
import type { AxiosError } from 'axios'

type FetcherConfig = {
  msalInstance?: ReturnType<typeof setupMSAL>
  systemCode: string
}

type FetcherProps<TBody = Record<string, unknown>> = [string, TBody | undefined]

const axiosRetry = getRetryAxios()

export const generateFetcher = (config?: FetcherConfig) => {
  const msalInstance = config?.msalInstance
  const { client, request } = msalInstance || {}

  const fetcher = async <TData, TBody>([
    endpoint,
    body,
  ]: FetcherProps<TBody>): Promise<TData> => {
    const azureADToken =
      client && request
        ? await client
            .acquireTokenSilent(request)
            .then((res) => res.accessToken)
            .catch(async (e) => {
              // eslint-disable-next-line no-console
              console.error(e) // NOSONAR
              if (e instanceof InteractionRequiredAuthError) {
                await client.loginRedirect({ ...request, prompt: 'login' })
              }
            })
        : undefined

    const options = {
      method: body ? 'POST' : 'GET',
      headers: {
        'Content-Type': 'application/json',
        'correlation-id': uuidv4(),
        authorization: azureADToken
          ? // To indicate the appropriate msal-node configs to use in api routes for auth
            // validation and since there are no security/extensibility concerns,
            // we just extend the token string with the system code
            `Bearer ${azureADToken}:${config?.systemCode}`
          : '',
      },
      ...(body ? { data: body } : {}),
      url: endpoint,
    }

    const res = await axiosRetry(options)
      .then((r) => {
        if (r.status === StatusCodes.NO_CONTENT) {
          throw new RequestError(StatusCodes.NO_CONTENT, r.statusText)
        }
        return r.data
      })
      .catch((e: AxiosError | RequestError) => {
        // eslint-disable-next-line no-console
        console.error(e) // NOSONAR
        if (axios.isAxiosError(e)) {
          const axiosError = e.response
          throw new RequestError(
            axiosError?.status || StatusCodes.INTERNAL_SERVER_ERROR,
            e.response?.statusText,
            e.response?.data,
          )
        }

        throw new RequestError(e.status, e.message, e.info)
      })

    return res
  }

  return fetcher
}
