import { createApiMockHandlers } from './createApiMockHandlers'
import { initServerApiMocks } from './initServerApiMocks'
import { initWorkerApiMocks } from './initWorkerApiMocks'

import type { ClientConfig } from '@ngb-frontend/shared/types'

// Don't inform about unhandled requests to these urls
const bypassUrls = `leaseplan|googleapis|maps|fonts|openstreetmap`

const mswOptions = {
  onUnhandledRequest: ({ url, method }: { url?: URL; method: string }) => {
    if (!url) return

    if (!new RegExp(`${bypassUrls}|${window.location.host}`).test(`${url}`)) {
      throw new Error(`Unhandled ${method} request to ${url}`)
    }
  },
}

/**
 * NB!: When a server is used (like in tests), ensure that it is closed properly
 * after all the tests of the suite have finished.
 */
export const initApiMocks = (config: ClientConfig, useServer = false) => {
  const handlers = createApiMockHandlers(config)
  const isServer = useServer || typeof window === 'undefined'
  if (isServer) {
    return initServerApiMocks(handlers, mswOptions)
  }

  return initWorkerApiMocks(handlers, mswOptions)
}
