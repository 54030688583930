import { staticMnRDCCMalfunctions } from '@ngb-frontend/content'

import type {
  MalfunctionCode,
  MalfunctionFormValues,
} from '@ngb-frontend/shared/types'

export const extractMalfunctionCodes = (
  dict?: MalfunctionFormValues['malfunctions'],
) => {
  return Object.values(dict || {})
    .flat()
    .filter(Boolean) as unknown as MalfunctionCode[]
}

export const groupMalfunctionCodes = (malfunctionCodes?: MalfunctionCode[]) => {
  return malfunctionCodes?.reduce((acc, curr) => {
    const dict = Object.entries(staticMnRDCCMalfunctions).find(([_, codes]) =>
      codes.includes(curr),
    )

    if (!dict) return acc

    const [group] = dict
    // @ts-ignore
    if (!acc[group]) acc[group] = []
    // @ts-ignore
    acc[group].push(curr)

    return acc
  }, {})
}
