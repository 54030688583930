import { makeStyles } from '@material-ui/core'
import { mq } from '@velocity/styling'

import { LAYOUT_MAX_WIDTH } from '../StepLayout/StepLayout.styled'

import type { Theme } from '@material-ui/core'

export const useBannerStyles = makeStyles((theme: Theme) => ({
  banner: {
    background: theme.palette.primary.main,
    minHeight: 'calc(var(--vel-fixed-spacing-06) * 8)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative',
    [mq.min('LG')]: {
      minHeight: 'calc(var(--vel-fixed-spacing-06) * 16)',
    },
  },
  backgroundImage: {
    pointerEvents: 'none',
    objectFit: 'cover',
    objectPosition: '80% 50%',
  },
  headlineContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: 'var(--vel-fixed-spacing-05)',
    zIndex: 1,
    [mq.min('LG')]: {
      alignItems: 'center',
    },
  },
  headlineInner: {
    width: '100%',
    maxWidth: LAYOUT_MAX_WIDTH,
    flexGrow: 1,
  },
  headline: {
    '&&': {
      color: theme.palette.common.white,
      [mq.min('LG')]: {
        width: '50%',
      },
    },
  },
}))
