import { setupWorker } from 'msw'

import type {
  RestHandler,
  MockedRequest,
  DefaultRequestBody,
  SharedOptions,
} from 'msw'

export const initWorkerApiMocks = (
  handlers: RestHandler<MockedRequest<DefaultRequestBody>>[],
  options?: SharedOptions | undefined,
) => {
  const worker = setupWorker(...handlers)
  worker.start(options)
  return worker
}
