import * as R from 'ramda'
import React, { useMemo } from 'react'

import {
  camelize,
  getServiceStepDataFromBooking,
  useQuery,
  useVehicle,
} from '@ngb-frontend/shared/utils'

import { ServiceForm } from '../../forms/ServiceForm/ServiceForm'

import type {
  ServiceFormValues,
  ServiceStepComponentProps,
  ServiceStepPrerequisiteData,
  StepProps,
} from '@ngb-frontend/shared/types'

const ServiceStep: React.FC<
  StepProps<
    ServiceFormValues,
    ServiceStepPrerequisiteData,
    ServiceStepComponentProps
  >
> = ({ onNextStep, onUpdateStep, stepData, variant, booking }) => {
  const variantKey = camelize(variant) as keyof ServiceFormValues

  const initialValuesRef = React.useRef<ServiceFormValues | undefined>(
    hasServiceFormValues(stepData, variantKey)
      ? /**
         * To make typing interactions with the form easier, from this point and
         * until data have to be written in persistence we call all variants
         * of services 'services'.
         */
        { services: stepData[variantKey] }
      : booking
      ? getServiceStepDataFromBooking(booking, variantKey)
      : { services: [] },
  )
  const query = useQuery()
  const { data: vehicle } = useVehicle({
    // Always pass an ilan and/or licensePlate to this hook call to enable app state overrides
    vehicleIdentifier: query?.vehicleIdentifier,
    country: query?.country,
  })

  const handleSubmit = React.useCallback(
    (values: ServiceFormValues) => {
      onUpdateStep(values)
      onNextStep()
    },
    [onNextStep, onUpdateStep],
  )

  const enabledServices = useMemo(
    () =>
      variantKey === 'extraServices'
        ? {
            ReplacementVehicle: true,
            CollectAndReturn: true,
          }
        : vehicle?.[variantKey],
    [variantKey, vehicle],
  )

  return (
    <ServiceForm
      variant={variant}
      onSubmit={handleSubmit}
      initialValues={initialValuesRef.current}
      enabledServices={enabledServices}
    />
  )
}

export const hasServiceFormValues = (
  x: unknown,
  variantDataKey: string,
): x is ServiceFormValues =>
  R.allPass([R.pipe(R.prop(variantDataKey), Array.isArray)])(x)

export default ServiceStep
