export class RequestError extends Error {
  public status: number
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public info: any // NOSONAR

  /**
   * The extra fields of this constructor should be used to customize our error
   * reporting in the future.
   */
  constructor(
    status: number,
    message = 'An error occurred while fetching data.',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    info?: any, // NOSONAR
  ) {
    super(message)
    this.status = status
    this.info = info
    Object.setPrototypeOf(this, RequestError.prototype)
  }
}
