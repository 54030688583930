import { lazy, Suspense } from 'react'

import { MaintenanceAndRepair as M } from '@ngb-frontend/shared/types'
import { FullScreenLoader } from '@ngb-frontend/shared/ui'
import { camelize } from '@ngb-frontend/shared/utils'

import { hasInspectionFormValues } from '../InspectionStep/InspectionStep'
import { hasMalfunctionFormValues } from '../MalfunctionStep/MalfunctionStep'
import { hasServiceFormValues } from '../ServiceStep/ServiceStep'

import type {
  AdditionalInformationStepPrerequisiteData,
  AdditionalInformationStepValues,
  StepComponentContainer,
} from '@ngb-frontend/shared/types'

const AdditionalInformationStepLazy = lazy(
  () => import('./AdditionalInformationStep'),
)

export const AdditionalInformationStepContainer: StepComponentContainer<
  AdditionalInformationStepValues,
  AdditionalInformationStepPrerequisiteData
> = (props) => {
  return (
    <Suspense fallback={<FullScreenLoader />}>
      <AdditionalInformationStepLazy {...props} />
    </Suspense>
  )
}

AdditionalInformationStepContainer.areStepPrerequisitesMet = (
  mergedFlowData,
): mergedFlowData is AdditionalInformationStepPrerequisiteData => {
  if (mergedFlowData?.services?.includes('MOT')) {
    return hasInspectionFormValues(mergedFlowData)
  }

  if (mergedFlowData?.services?.includes('Repair')) {
    return hasMalfunctionFormValues(mergedFlowData)
  }

  return hasServiceFormValues(mergedFlowData, camelize(M.Service))
}
