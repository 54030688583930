/**
 * This is a manual helper typing for the LeaseChange.status (string) type
 */
export enum LeaseChangeStatus {
  ResolvedCancelled = 'Resolved-Cancelled',
  ResolvedActivated = 'Resolved-Activated',
  ResolvedInvalid = 'Resolved-Invalid',
  PendingInProgress = 'Pending-InProgress',
  PendingQuoteAcceptance = 'Pending-QuoteAcceptance',
  PendingConfirmation = 'Pending-Confirmation',
  New = 'New',
}
