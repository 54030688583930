import useSWR from 'swr'

import { useAppConfig } from '@ngb-frontend/shared/context'

import { useQuery } from './useQuery'

import type { RequestError } from '../error/RequestError'
import type {
  CountryCode,
  Vehicle,
  VehicleIdentifier,
} from '@ngb-frontend/shared/types'

type Props = {
  vehicleIdentifier?: VehicleIdentifier
  country?: CountryCode
}

/**
 * @param vehicleIdentifier - The vehicleIdentifier to use for fetching, if not provided, it will try to get it from the query.
 */
export const useVehicle = (props?: Props) => {
  const { vehicleIdentifier, country } = props || {}

  const config = useAppConfig()
  const initQuery = useQuery()

  const resolvedVehicleId = vehicleIdentifier || initQuery?.vehicleIdentifier
  const resolvedCountry = country || initQuery?.country

  return useSWR<Vehicle, RequestError>(
    // Fetches only uncached contracts. Thus, for new ILANs or unresolved old.
    resolvedVehicleId
      ? [
          config.apiRoutes.vehicle,
          { vehicleIdentifier: resolvedVehicleId, country: resolvedCountry },
        ]
      : null,
  )
}
