import type { Vehicle } from '@ngb-frontend/shared/types'

// Please try to keep this relevant with the output of getUniversalVehicle(mockContract, mockVehicleObject)
export const mockVehicle: Vehicle = {
  state: { hasActiveContract: true },
  licensePlate: 'EK 5699',
  isCustomLicense: false,
  driverIdentifier: 'C-12345',
  make: undefined,
  objectType: undefined,
  registrationDate: '2021-08-26',
  warrantyEndDate: '2022-10-26',
  warrantyMileage: 20000,
  motExpiry: '2024-08-04T18:29:14.766Z',
  contractedMonths: 36,
  serviceIntervalMileage: 5000,
  serviceIntervalMonths: 36,
  mostRecentMileage: 5000,
  contractedStartDate: '2022-02-22T22:22:22.000Z',
  contractedEndDate: '2024-08-04T18:29:14.751Z',
  vin: '3Y1SL65848Z411439',
  contractStatus: 'Active',
  leaseProduct: 'Foo Bar',
  contractedMileage: '',
  replacementVehicleCategory: 'categoryC',
  roadTaxExpiry: '2024-01-15',
  services: { RegularMaintenance: true, MOT: true, EOL: true, Repair: true },
  extraServices: { ReplacementVehicle: true, CollectAndReturn: true },
}
