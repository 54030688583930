import { lazy, Suspense } from 'react'

import { FullScreenLoader } from '@ngb-frontend/shared/ui'

import type {
  MileageStepValues,
  StepComponentContainer,
} from '@ngb-frontend/shared/types'

const MileageStepLazy = lazy(() => import('./MileageStep'))

export const MileageStepContainer: StepComponentContainer<MileageStepValues> = (
  props,
) => {
  return (
    <Suspense fallback={<FullScreenLoader />}>
      <MileageStepLazy {...props} />
    </Suspense>
  )
}

MileageStepContainer.areStepPrerequisitesMet = (
  _mergedFlowData,
): _mergedFlowData is {} => true
