import { lazy, Suspense } from 'react'

import { StepVariants } from '@ngb-frontend/shared/types'
import { FullScreenLoader } from '@ngb-frontend/shared/ui'

import { hasReplacementVehicleValues } from '../ReplacementVehicleStep/ReplacementVehicleStep'

import type {
  DateStepPrerequisiteData,
  DateStepValues,
  StepComponentContainer,
} from '@ngb-frontend/shared/types'

const DateStepLazy = lazy(() => import('./DateStep'))

export const DateStepContainer: StepComponentContainer<
  DateStepValues,
  DateStepPrerequisiteData
> = (props) => {
  return (
    <Suspense fallback={<FullScreenLoader />}>
      <DateStepLazy {...props} />
    </Suspense>
  )
}

DateStepContainer.areStepPrerequisitesMet = (
  mergedFlowData,
): mergedFlowData is DateStepPrerequisiteData =>
  hasReplacementVehicleValues(mergedFlowData)

DateStepContainer.getVariant = (mergedFlowData) =>
  mergedFlowData.extraServices?.includes('CollectAndReturn')
    ? StepVariants.DateWithPickup
    : StepVariants.Date
