import type {
  ClientStaticConfig,
  SharedStaticConfig,
} from '@ngb-frontend/shared/types'

export const sharedStaticConfig: SharedStaticConfig = {
  apiRoutes: {
    driver: '/api/driver',
    booking: '/api/booking',
    createBooking: '/api/booking?action=create',
    updateBooking: '/api/booking?action=update',
    fetchBooking: '/api/booking?action=fetch',
    fetchBookingHistory: '/api/booking?action=fetchHistory',
    leaseChanges: '/api/contract/leaseChanges',
    supplierSearch: '/api/supplierSearch',
    vehicle: '/api/vehicle',
    serviceHistory: '/api/serviceHistory',
  },
  endpoints: {
    azurePublicKeys: 'https://login.microsoftonline.com',
    booking: 'booking',
    driver: 'driver',
    contract: 'contracts',
    supplierSearch: 'supplier-search',
    vehicleObject: 'vehicle-objects',
    serviceHistory: 'service_history/views/service_history',
    vehicleList: 'vehicle/views/vehicle-list',
    vehicleDetails: 'vehicle/views/vehicle-details',
  },
}

export const clientStaticConfig: ClientStaticConfig = {
  localStorageKeys: {
    swrCache: 'appCache',
    flow: 'ngb-flow',
    queryParams: 'queryParams',
    bookingHistoryChecked: 'bookingHistoryChecked',
    customPlatesChecked: 'customPlatesChecked',
  },
}
