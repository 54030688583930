import { Grid } from '@velocity/ui'
import React from 'react'
import { Form } from 'react-final-form'

import { FormFooter, MileageField } from '@ngb-frontend/shared/ui'

import type {
  MileageFormValues,
  StepFormProps,
} from '@ngb-frontend/shared/types'

export const MileageForm: React.FC<StepFormProps<MileageFormValues>> = ({
  initialValues,
  onSubmit,
}) => {
  const handleSubmit = React.useCallback(
    (values: MileageFormValues) => {
      onSubmit?.({ mileage: Number(values.mileage) }) // submitted as string
    },
    [onSubmit],
  )

  return (
    <Form<MileageFormValues>
      onSubmit={handleSubmit}
      initialValues={initialValues}
    >
      {(formProps) => (
        <form
          onSubmit={formProps.handleSubmit}
          data-e2e-component="mileage-form"
        >
          <Grid>
            <Grid.Item LG={4}>
              <MileageField hideLabel />
            </Grid.Item>
          </Grid>
          <FormFooter />
        </form>
      )}
    </Form>
  )
}
