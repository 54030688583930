import * as R from 'ramda'
import React, { useMemo } from 'react'

import {
  formatInTimeZone,
  formatZonedToUTC,
  useContent,
} from '@ngb-frontend/content'
import {
  getServiceOperatingHours,
  setDateTime,
  splitDateTime,
  useQuery,
} from '@ngb-frontend/shared/utils'

import { DateForm } from '../../forms/DateForm/DateForm'

import type {
  CountryCode,
  DateFormValues,
  DateStepPrerequisiteData,
  DateStepValues,
  StepProps,
} from '@ngb-frontend/shared/types'

const DateStep: React.FC<
  StepProps<DateStepValues, DateStepPrerequisiteData>
> = ({ onNextStep, onUpdateStep, stepData, booking, mergedData }) => {
  const c = useContent()
  const query = useQuery()

  const initialValuesRef = React.useRef<DateFormValues | undefined>(
    hasDateFormValues(stepData)
      ? {
          ...splitDateTime(
            formatInTimeZone(stepData.dateTime, {
              country: query?.country as CountryCode | never,
            }),
          ),
          alternativeDates: stepData.alternativeDates,
        }
      : booking
      ? splitDateTime(
          formatInTimeZone(booking.service_date_time || '', {
            country: booking.country_code,
          }),
        )
      : undefined,
  )

  const hasPickup = mergedData.extraServices?.includes('CollectAndReturn')

  const hasPickupOrCourtesy = hasPickup || mergedData.courtesyVehicle
  const minDate = new Date()
  // countryConfig
  minDate.setDate(minDate.getDate() + (hasPickupOrCourtesy ? 14 : 7))

  /**
   * We manipulate time in the following manner
   * 1) Create a date object (always system timezone) and set date and hours based on form values
   *    This means that for UK in a Dutch browser, we use the Dutch TimeZone but this is
   *    not problematic since we store the date ISO string only after (2)
   * 2) Convert the date object to a UTC ISO string to store in the state of the app / send to BE
   * 3) Whenever we read a date from state, we format it (see getZonedDateTime)
   *    through the "formatInTimeZone" function, which formats a date in TimeZone
   *    regardless of the system timezone
   */
  const handleSubmit = React.useCallback(
    (values: DateFormValues) => {
      const localDateTime = setDateTime(values.date, values.time)
      const utcDateTime = formatZonedToUTC(localDateTime, {
        country: query?.country as CountryCode | never,
      })

      onUpdateStep({
        dateTime: utcDateTime.toISOString(),
        alternativeDates: values.alternativeDates,
      })
      onNextStep()
    },
    [onNextStep, onUpdateStep, query?.country],
  )

  const timeConfig = useMemo(() => {
    const mergedServices = [
      ...(mergedData.services || []),
      ...(mergedData.extraServices || []),
    ]

    return getServiceOperatingHours(mergedServices)
  }, [mergedData.extraServices, mergedData.services])

  return (
    <DateForm
      // countryConfig
      fixedTime={hasPickup ? '10:30' : undefined}
      hint={hasPickup ? c('fields.date.hint') : ''}
      timeConfig={timeConfig}
      onSubmit={handleSubmit}
      initialValues={initialValuesRef.current}
      minDate={minDate.toISOString().split('T')[0]}
    />
  )
}

export const hasDateFormValues = (x: unknown): x is DateStepValues =>
  R.allPass([R.pipe(R.prop('dateTime'), R.is(String))])(x)

export default DateStep
