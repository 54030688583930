import type { InspectionSets } from '@ngb-frontend/shared/types'

export const staticInspections: InspectionSets = {
  motorcycles: ['LegalInspectionClass1', 'LegalInspectionClass2'],
  tricycle: [
    'LegalInspectionClass3',
    {
      code: 'LegalInspectionClass4',
      translation: 'LegalInspectionClass4Tricycle',
    },
  ],
  passengerVehicles: [
    'LegalInspectionClass4',
    'LegalInspectionClass4a',
    'LegalInspectionClass5',
  ],
  commercialVehicles: ['LegalInspectionClass7'],
  miniBus: ['MinibusSafetyInspection'],
  equipment: ['PUWER', 'LOLER'],
  preMOT: [
    'NorthernIrelandPreMOTClass1',
    'NorthernIrelandPreMOTClass2',
    'NorthernIrelandPreMOTClass3',
    'NorthernIrelandPreMOTClass4',
    'NorthernIrelandPreMOTClass4a',
    'NorthernIrelandPreMOTClass5',
    'NorthernIrelandPreMOTClass7',
  ],
}
