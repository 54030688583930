import { lazy, Suspense } from 'react'

import {
  type SelectServiceLocationStepPrerequisiteData,
  type SelectServiceLocationStepValues,
  type StepComponentContainer,
} from '@ngb-frontend/shared/types'
import { FullScreenLoader } from '@ngb-frontend/shared/ui'

import { hasLocationValues } from '../LocationStep/LocationStep'

const SelectServiceLocationStepLazy = lazy(
  () => import('./SelectServiceLocationStep'),
)

export const SelectServiceLocationStepContainer: StepComponentContainer<
  SelectServiceLocationStepValues,
  SelectServiceLocationStepPrerequisiteData
> = (props) => {
  return (
    <Suspense fallback={<FullScreenLoader />}>
      <SelectServiceLocationStepLazy {...props} />
    </Suspense>
  )
}

SelectServiceLocationStepContainer.areStepPrerequisitesMet = (
  mergedFlowData,
): mergedFlowData is SelectServiceLocationStepPrerequisiteData =>
  hasLocationValues(mergedFlowData)

SelectServiceLocationStepContainer.skip = (mergedFlowData) => ({
  enabled: !!mergedFlowData?.extraServices?.includes('CollectAndReturn'),
  state: { supplier: undefined },
})
