import { Input } from '@velocity/ui'

import { useContent } from '@ngb-frontend/content'
import { validation as v } from '@ngb-frontend/shared/utils'

import { FormField } from '../FormField/FormField'

import type { FormFieldWrapper } from '@ngb-frontend/shared/types'

export const EmailField: React.FC<FormFieldWrapper> = ({
  classes,
  name = 'email',
  disabled,
  noMargin,
  label,
  hideLabel,
  hint,
  noValidation,
  placeholder,
}) => {
  const c = useContent()

  return (
    <FormField<string>
      name={name}
      id={`${name}-input`}
      label={label || c('fields.email.label')}
      hint={hint}
      hideLabel={hideLabel}
      validator={v.apply(v.required, {
        message: c('errors.required'),
        type: 'errorType',
      })}
      noValidation={noValidation}
      classes={classes}
      noMargin={noMargin}
      disabled={!!disabled}
    >
      {({ input }) => (
        <Input
          inputProps={{
            'data-e2e-component': `${name}-input`,
          }}
          {...input}
          type="email"
          placeholder={placeholder ?? c('fields.email.placeholder')}
          required={false}
          disabled={!!disabled}
        />
      )}
    </FormField>
  )
}
