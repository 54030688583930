import { addDays } from 'date-fns'

import { mockSupplier } from './supplier'
import { mockVehicleObject } from './vehicleObject'

import type {
  ServiceHistoryItem,
  ServiceHistory,
} from '@ngb-frontend/shared/types'

export const mockServiceHistoryItem: ServiceHistoryItem = {
  job_id: '537956',
  job_date: '2022-02-22T22:22:22+0000',
  job_status_code: 'CLOSED',
  odometer_reading: 216505,
  po_number: '',
  registration_number: mockVehicleObject.registration_number || '',
  lp_entity_code: 'LPUK',
  object_number: '2173622',
  supplier_name: `${mockSupplier.name}`,
  supplier_key: mockSupplier.id,
  supplier_town: `${mockSupplier.address?.city}`,
  estimate_total: 476.76,
  lines: [
    {
      operation_code: '001',
      operation_description: 'Wiper blades kit',
    },
    {
      operation_code: '002',
      operation_description: 'Replace windshield wipers',
    },
    {
      operation_code: '003',
      operation_description: 'Replace battery',
    },
  ],
  vin: mockVehicleObject.uin || '',
  source: 'lpuk',
}

export const mockServiceHistoryRes: Required<ServiceHistory> = {
  name: 'service-history',
  description: '',
  elements: Array.from({ length: 6 }).map((_, idx) => ({
    ...mockServiceHistoryItem,
    job_id: `NGRE2E-000000${idx}`,
    job_date: addDays(
      new Date(mockServiceHistoryItem.job_date || ''),
      idx * 120,
    ).toUTCString(),
  })),
} as Required<ServiceHistory>
