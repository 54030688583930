import { deAT, enGB, es, fr, frCH, ro, sv } from 'date-fns/locale'

export const defaultLocale: keyof typeof locales = 'enGB'

const locales = {
  deAT,
  enGB,
  es,
  fr,
  frCH,
  ro,
  sv,
}

export type DateFnsLocale = keyof typeof locales

export const getDateFnsLocale = (id: DateFnsLocale) => locales[id]

export const defaultDateFnsLocale = locales[defaultLocale]
