import * as R from 'ramda'
import React from 'react'

import { hasAddress } from '@ngb-frontend/shared/utils'

import { OtherLocationForm } from '../../forms/OtherLocationForm/OtherLocationForm'

import type {
  OtherLocationFormValues,
  OtherLocationStepPrerequisiteData,
  OtherLocationStepValues,
  StepProps,
} from '@ngb-frontend/shared/types'

const OtherLocationStep: React.FC<
  StepProps<OtherLocationStepValues, OtherLocationStepPrerequisiteData>
> = ({ onNextStep, onUpdateStep, stepData, stepVariant }) => {
  const initialValuesRef = React.useRef<OtherLocationFormValues | undefined>(
    hasOtherLocationValues(stepData)
      ? { address: stepData.otherLocation }
      : undefined,
  )

  const handleSubmit = React.useCallback(
    (values: OtherLocationFormValues) => {
      onUpdateStep({ otherLocation: values.address })
      onNextStep()
    },
    [onNextStep, onUpdateStep],
  )

  return (
    <OtherLocationForm
      stepVariant={stepVariant}
      onSubmit={handleSubmit}
      initialValues={initialValuesRef.current}
    />
  )
}

export const hasOtherLocationValues = (
  x: unknown,
): x is OtherLocationFormValues =>
  R.allPass([R.pipe(R.prop('otherLocation'), hasAddress)])(x)

export default OtherLocationStep
