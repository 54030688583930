import { SystemCode, UserType } from '@ngb-frontend/shared/types'

import type { QueryParams } from '@ngb-frontend/shared/types'

export const getUserType = (
  systemCode?: Partial<QueryParams['systemCode']>,
) => {
  if (systemCode === SystemCode.MYAY || systemCode === SystemCode.MYLP)
    return UserType.Driver

  if (systemCode === SystemCode.SFLP || systemCode === SystemCode.SFALD)
    return UserType.Agent

  if (systemCode === SystemCode.SPLP) return UserType.Broker

  return UserType.Unknown
}
