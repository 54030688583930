import { Grid, Text } from '@velocity/ui'
import React, { useCallback } from 'react'
import { Form } from 'react-final-form'

import { useContent } from '@ngb-frontend/content'
import { FormFooter, MalfunctionsField } from '@ngb-frontend/shared/ui'
import {
  string,
  hasTruthyFields,
  validation as v,
} from '@ngb-frontend/shared/utils'

import type {
  MalfunctionFormProps,
  MalfunctionFormValues,
} from '@ngb-frontend/shared/types'

export const MalfunctionForm: React.FC<MalfunctionFormProps> = ({
  onSubmit,
  initialValues,
  malfunctions,
}) => {
  const c = useContent()
  const handleSubmit = React.useCallback(
    (values: MalfunctionFormValues) => {
      const { malfunctionDescription } = values

      onSubmit?.({
        ...values,
        malfunctionDescription: string(malfunctionDescription),
      })
    },
    [onSubmit],
  )

  const validator = useCallback(
    (values: MalfunctionFormValues) => ({
      malfunctions: v.apply(v.minLength(1), {
        message: c('errors.malfunctionRequired'),
        type: 'errorType',
      })(
        string(values.malfunctionDescription) ||
          Object.values(values?.malfunctions || {}).flat(),
      ),
    }),
    [c],
  )

  return (
    <Form<MalfunctionFormValues>
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validate={validator}
    >
      {(formProps) => (
        <form
          onSubmit={formProps.handleSubmit}
          data-e2e-component="malfunction-form"
        >
          <Grid margin={{ bottom: '07' }}>
            <Grid.Item LG={8}>
              <MalfunctionsField
                row
                malfunctions={malfunctions}
                initialValues={initialValues}
                error={formProps.errors?.malfunctions}
                dirty={
                  hasTruthyFields(formProps.modified) || formProps.submitFailed
                }
              />
            </Grid.Item>
          </Grid>
          <Text variant="100">{c('steps.malfunction.notCovered')}</Text>
          <FormFooter />
        </form>
      )}
    </Form>
  )
}
