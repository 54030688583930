import { UserProvider } from '@auth0/nextjs-auth0'
import { MsalProvider } from '@azure/msal-react'

import type { PublicClientApplication } from '@azure/msal-browser'
import type { ReactNode } from 'react'

interface AuthProviderProps {
  disabled?: boolean
  msalApp?: PublicClientApplication
  children?: ReactNode
}

export const AuthProvider: React.FC<AuthProviderProps> = (props) => {
  const { disabled, msalApp, children } = props

  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (disabled) return <>{children}</>

  return (
    <UserProvider>
      {msalApp ? (
        <MsalProvider instance={msalApp}>{children}</MsalProvider>
      ) : (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>{children}</>
      )}
    </UserProvider>
  )
}
