import { mockAddress, mockAddressAlt } from './address'

import type { Driver } from '@ngb-frontend/shared/types'

export const mockDriver: Driver = {
  id: '3030202033920',
  country_code: 'LPUK',
  first_name: 'Michelle',
  last_name: 'Enders',
  salutation: 'Prof',
  email: 'janedoe@example.com',
  mobile_phone: '0612345678',
  language_preference: 'EN',
  communication_preference: 'email',
  home_address: mockAddressAlt,
  work_address: mockAddress,
  pickup_address: mockAddress,
}

export const fetchDriver = () => Promise.resolve(mockDriver)
