import { makeStyles } from '@material-ui/core/styles'
import { mq } from '@velocity/styling'

import type { Theme } from '@material-ui/core/styles'

type Props = { showLogo?: boolean }

export const useNavigationBarStyles = makeStyles<Theme, Props>(() => ({
  navWrapper: {
    '& svg': {
      width: 'fit-content',
      flex: '0 0 auto',
    },
    '&& div:has(> nav)': {
      flex: '1 0 auto',
    },
  },
  root: {
    position: 'relative',
    alignItems: 'center',
    minHeight: 66,
    padding: 'var(--vel-fixed-spacing-02)',
    justifyContent: ({ showLogo }: Props) =>
      showLogo ? 'space-around' : 'space-between',
  },
  label: {
    display: 'flex',
    justifyContent: 'center',
    '&&': {
      color: 'var(--vel-color-neutral-900)',
    },
  },
  progressBar: {
    '&&': {
      position: 'absolute',
      left: 0,
      bottom: 0,
      borderRadius: 'var(--vel-shape100-border_radius)',
      '& > div': {
        backgroundColor: 'var(--vel-color-primary-400)',
      },
    },
  },
  progressText: {
    display: 'none',
    textAlign: 'center',
    color: 'var(--vel-color-neutral-600)',
    [mq.min('LG')]: {
      display: 'block',
    },
  },
  button: {
    '&&': {
      color: 'var(--vel-color-primary-400)',
      padding: 'var(--vel-fixed-spacing-02)',
      height: '100%',
      '&&:hover': {
        background: 'none',
      },
      [mq.min('LG')]: {
        padding: 'var(--vel-fixed-spacing-06)',
      },
    },
  },
  logo: {
    '&&': {
      display: ({ showLogo }: Props) => (showLogo ? 'block' : 'none'),
    },
  },
}))
