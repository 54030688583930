import { makeStyles } from '@material-ui/core'

import type { Theme } from '@material-ui/core'

export const LAYOUT_MAX_WIDTH = 1200

export const useStepLayoutStyles = makeStyles<
  Theme,
  { fullWidth: boolean; showLogo?: boolean }
>(() => ({
  root: {
    height: '100vh',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    height: '100%',
    overflow: 'auto',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: 'var(--vel-fixed-spacing-05)',
    paddingBottom: 'calc(var(--vel-fixed-spacing-09) * 4)',
    paddingTop: (props) =>
      props.showLogo
        ? 'var(--vel-fixed-spacing-09)'
        : 'var(--vel-fixed-spacing-05)',
    alignItems: 'center',
  },
  inner: {
    width: '100%',
    maxWidth: ({ fullWidth }) => (fullWidth ? '100%' : LAYOUT_MAX_WIDTH),
    flexGrow: 1,
  },
  headline: {
    '&&': {
      color: 'var(--vel-color-neutral-white)',
    },
  },
  title: {
    '&&': {
      margin: 'var(--vel-fixed-spacing-03) 0 var(--vel-fixed-spacing-07) 0',
      fontWeight: 'normal',
    },
  },
}))
