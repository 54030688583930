import { PhoneInput } from '@velocity/ui'

import { useContent } from '@ngb-frontend/content'
import { validation as v } from '@ngb-frontend/shared/utils'

import { FormField } from '../FormField/FormField'

import type { FormFieldWrapper } from '@ngb-frontend/shared/types'

export const PhoneField: React.FC<Omit<FormFieldWrapper, 'placeholder'>> = ({
  classes,
  name = 'phone',
  label,
  hideLabel,
  disabled,
  noMargin,
  noValidation,
}) => {
  const c = useContent()
  return (
    <FormField<string>
      name={name}
      id={`${name}-input`}
      label={label || c('fields.phone.label')}
      hideLabel={hideLabel}
      validator={v.apply(v.required, {
        message: c('errors.required'),
        type: 'errorType',
      })}
      noValidation={noValidation}
      classes={classes}
      noMargin={noMargin}
      disabled={!!disabled}
    >
      {({ input }) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { onFocus, onBlur, ...rest } = input

        return (
          <PhoneInput
            {...rest}
            inputProps={{
              'data-e2e-component': `${name}-input`,
            }}
            required={false}
            disabled={!!disabled}
          />
        )
      }}
    </FormField>
  )
}
