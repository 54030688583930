import { lazy, Suspense } from 'react'

import { MaintenanceAndRepair } from '@ngb-frontend/shared/types'
import { FullScreenLoader } from '@ngb-frontend/shared/ui'
import { camelize } from '@ngb-frontend/shared/utils'

import { hasMalfunctionFormValues } from '../MalfunctionStep/MalfunctionStep'
import { hasServiceFormValues } from '../ServiceStep/ServiceStep'

import type {
  InspectionStepPrerequisiteData,
  InspectionStepValues,
  StepComponentContainer,
} from '@ngb-frontend/shared/types'

const InspectionStepLazy = lazy(() => import('./InspectionStep'))

export const InspectionStepContainer: StepComponentContainer<
  InspectionStepValues,
  InspectionStepPrerequisiteData
> = (props) => {
  return (
    <Suspense fallback={<FullScreenLoader />}>
      <InspectionStepLazy {...props} />
    </Suspense>
  )
}

InspectionStepContainer.areStepPrerequisitesMet = (
  mergedFlowData,
): mergedFlowData is InspectionStepPrerequisiteData =>
  (mergedFlowData?.services?.includes('Repair') &&
    hasMalfunctionFormValues(mergedFlowData)) ||
  hasServiceFormValues(mergedFlowData, camelize(MaintenanceAndRepair.Service))

InspectionStepContainer.skip = (mergedFlowData) => ({
  enabled: !mergedFlowData.services?.includes('MOT'),
  state: { inspection: '' },
})
