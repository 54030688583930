import { BookingStatus } from '@ngb-frontend/shared/types'

import { mockBooking } from './booking'

import type { Booking, ServiceValue } from '@ngb-frontend/shared/types'

const services: ServiceValue[] = ['RegularMaintenance', 'Repair', 'MOT', 'EOL']
const statusCodes: BookingStatus[] = Object.values(BookingStatus)

export const mockBookingHistory: Booking[] = services.reduce<Booking[]>(
  (acc, curr, idx) => {
    return [
      ...acc,
      ...statusCodes.map((status, idxx) => ({
        ...mockBooking,
        id: `${idx}${idxx}`,
        status,
        service: {
          items: [
            {
              code: curr,
              value: curr,
            },
          ],
        },
      })),
    ]
  },
  [],
)
