import { Loader } from '@velocity/ui'
import { FlexColumn } from '@velocity/ui/draft'

import { useFullscreenLoaderStyles } from './FullscreenLoader.styled'

import type { LoaderProps } from '@velocity/ui'
import type { FC } from 'react'

export interface FullScreenLoaderProps {
  /**
   * Optionally set the full screen loader to either be contained within its parent component, fixed
   * and cover the entire page.
   */
  variant?: 'contained' | 'fixed'
  size?: LoaderProps['size']
}

export const FullScreenLoader: FC<FullScreenLoaderProps> = ({
  variant = 'fixed',
  size = 'l',
}) => {
  const classes = useFullscreenLoaderStyles({ variant })

  return (
    <FlexColumn
      alignItems="center"
      justifyContent="center"
      data-e2e-component="fullscreen-loader"
      className={classes.root}
    >
      <Loader variant="primary" size={size} />
    </FlexColumn>
  )
}
