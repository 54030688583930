import { useUser as useAuth0 } from '@auth0/nextjs-auth0'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'

import { SystemCode } from '@ngb-frontend/shared/types'

import { AzureAuthTemplate } from './AzureAuthTemplate'

import type { AzureAuthTemplateProps } from './AzureAuthTemplate'
import type { ReactNode } from 'react'

// Apps which implement Auth0 SSO. The rest use MS Azure.
export const AUTH0_SYS = [SystemCode.MYLP, SystemCode.SPLP]
export const AZURE_SYS = [SystemCode.MYAY, SystemCode.SFALD, SystemCode.SFLP]

export type AuthTemplateProps = Partial<AzureAuthTemplateProps> & {
  systemCode: SystemCode
  children: ReactNode
}

const AuthComponentBase: React.FC<AuthTemplateProps> = ({
  children,
  systemCode,
  azureRequest,
  loadingComponent: LoadingComponent,
}) => {
  const router = useRouter()
  const { user, isLoading } = useAuth0()
  const needsAuth0User = AUTH0_SYS.includes(systemCode) && !user

  // Auth0 authentication
  useEffect(() => {
    if (needsAuth0User && !isLoading) {
      router.replace('/api/auth/login', undefined, { shallow: true })
    }
  }, [isLoading, needsAuth0User, router])

  if (needsAuth0User)
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return LoadingComponent ? <LoadingComponent /> : <></>

  return (
    // The template component facilitates the AzureAD authentication
    azureRequest && AZURE_SYS.includes(systemCode) ? (
      <AzureAuthTemplate
        azureRequest={azureRequest}
        loadingComponent={LoadingComponent}
      >
        {children}
      </AzureAuthTemplate>
    ) : (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>{children}</>
    )
  )
}

/**
 * Wrapper to conditionally render the auth implementation component. Requires
 * AuthProvider
 */
export const AuthComponent: React.FC<
  AuthTemplateProps & { disabled?: boolean }
> = ({ disabled, children, ...authBaseProps }) => {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (disabled) return <>{children}</>

  return <AuthComponentBase children={children} {...authBaseProps} />
}
