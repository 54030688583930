import { lazy, Suspense } from 'react'

import { StepVariants } from '@ngb-frontend/shared/types'
import { FullScreenLoader } from '@ngb-frontend/shared/ui'

import { hasDateFormValues } from '../DateStep/DateStep'

import type {
  OtherLocationStepPrerequisiteData,
  OtherLocationStepValues,
  StepComponentContainer,
} from '@ngb-frontend/shared/types'

const OtherLocationStepLazy = lazy(() => import('./OtherLocationStep'))
export const OtherLocationStepContainer: StepComponentContainer<
  OtherLocationStepValues,
  OtherLocationStepPrerequisiteData
> = (props) => {
  return (
    <Suspense fallback={<FullScreenLoader variant="fixed" />}>
      <OtherLocationStepLazy {...props} />
    </Suspense>
  )
}

OtherLocationStepContainer.areStepPrerequisitesMet = (
  mergedFlowData,
): mergedFlowData is OtherLocationStepPrerequisiteData =>
  hasDateFormValues(mergedFlowData)

OtherLocationStepContainer.skip = (mergedFlowData) => ({
  enabled: !!mergedFlowData.location,
  state: { otherLocation: '' },
})

OtherLocationStepContainer.getVariant = (mergedFlowData) => {
  const extraServices = mergedFlowData.extraServices || []

  if (extraServices.includes('CollectAndReturn')) {
    return StepVariants.OtherLocationWithPickup
  }

  if (extraServices.includes('ReplacementVehicle')) {
    return StepVariants.OtherLocationWithRPV
  }

  return StepVariants.OtherLocation
}
