export type Service<T extends ServiceOptionValue = ServiceValue> = {
  name?: string
  description?: string
  type?: string
  items?: ServiceItem<T>[]
}

// The actual service codes will be aligned with the BE
export type ServiceValue = 'RegularMaintenance' | 'Repair' | 'MOT' | 'EOL'
export type AdditionalServiceValue = 'CollectAndReturn' | 'ReplacementVehicle'
export type ServiceOptionValue = ServiceValue | AdditionalServiceValue

export type ServiceItem<T extends ServiceOptionValue = ServiceOptionValue> = {
  code: T
  value: T
}

export enum ServiceType {
  Isp = 'ISP',
  Oem = 'OEM',
}
