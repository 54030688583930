import { Input } from '@velocity/ui'

import { useContent } from '@ngb-frontend/content'
import { validation as v } from '@ngb-frontend/shared/utils'

import { FormField } from '../FormField/FormField'

import type { FormFieldWrapper } from '@ngb-frontend/shared/types'

export const CityField: React.FC<FormFieldWrapper> = ({
  classes,
  name = 'city',
  label,
  hideLabel,
  required = true,
  noMargin,
  disabled,
}) => {
  const c = useContent()
  return (
    <FormField<string>
      classes={classes}
      name={name}
      id="city-input"
      validator={
        required
          ? v.apply(v.required, {
              message: c('errors.required'),
              type: 'errorType',
            })
          : undefined
      }
      label={label || c('fields.city.label')}
      hideLabel={hideLabel}
      required={required}
      noMargin={noMargin}
      disabled={disabled}
      renderKey={required ? '1' : '0'}
    >
      {({ input }) => (
        <Input
          inputProps={{
            'aria-label': 'city',
            'data-e2e-component': 'city-input',
          }}
          {...input}
          type="string"
          required={false}
          disabled={disabled}
        />
      )}
    </FormField>
  )
}
