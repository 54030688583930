import { makeStyles } from '@material-ui/core'
import { mq } from '@velocity/styling'

import type { Theme } from '@material-ui/core'

export type Props = { row: boolean | undefined }

export const useServicesFieldStyles = makeStyles<Theme, Props>(() => ({
  checkboxCardGroup: ({ row }: Props) => ({
    '&&': {
      width: 'auto',
      margin: 'calc(calc(var(--vel-fixed-spacing-05) / 2) * -1)',
      ...(row
        ? {
            [mq.min('LG')]: {
              gap: 'var(--vel-layout-spacing-06)',
              display: 'grid',
              gridAutoFlow: 'column',
              gridAutoColumns: '1fr',
              margin: 0,
            },
          }
        : {}),
    },
    '&& > div': {
      [mq.min('LG')]: {
        padding: row ? 0 : 'auto',
      },
      padding: 'calc(var(--vel-fixed-spacing-05) / 2)',
      width: '100%',
      '& label': {
        width: '100%',
      },
    },
    padding: 'auto',
    width: '100%',
    '& label': {
      width: '100%',
    },
  }),
}))
