import { getTestSharedConfig } from './getTestSharedConfig'

import type { ServerConfig } from '@ngb-frontend/shared/types'

export const getTestServerConfig = (): ServerConfig => ({
  ...getTestSharedConfig(),
  disableToken: true,
  sfBase: 'https://leaseplan--lpmock.my.salesforce.com/services/apexrest',
  apiTokenUrl: '',
  tibco: {
    sfSecret: '',
    sfClientId: '',
    fleetopsContractManagementSecret: '',
    fleetopsLeaseObjectSecret: '',
    fleetopsContractManagementClientId: '',
    fleetopsLeaseObjectClientId: '',
  },
  fleetopsContractManagementBase:
    'https://fleetops-contract-management-api.mock.leaseplan.io/v1.0',
  fleetopsLeaseObjectBase:
    'https://fleetops-lease-object-api.mock.leaseplan.io/v1.0',
  denodo: {
    base: 'https://denodo-api.mock.leaseplan.io',
    username: 'user',
    password: 'pass',
  },
  ipInfoToken: '',
  smartling: {
    projectId: '',
    userId: '',
    secret: '',
    gitToken: '',
  },
})
