import {
  LegacyVehicleServiceCode,
  type VehicleDetailsItem,
  type VehicleDetails,
} from '@ngb-frontend/shared/types'

export const mockVehicleDetailsItem: VehicleDetailsItem = {
  registration: 'YS69OZB',
  object_id: '1298499',
  vin: 'WF0YXXTTGYKM15135',
  entity_code: 'LPUK',
  customer_id: 'L 33455',
  customer_name: 'FFEC7B595770C3CC398F',
  recharge_limit: 750,
  driver_name: 'E257 690C41703',
  manufacturer_code: '7',
  manufacturer_name: 'FORD',
  model_code: '53356',
  model_name: 'T/CS300L1 19.75',
  type_name: '105H2 LEADER',
  object_status_code: '1',
  object_status_name: 'Operational, vehicle in service',
  suspended: undefined,
  exterior_colour: 'Sld Frozen white',
  fuel_type_code: 'D',
  fuel_type_name: 'DIESEL',
  transission_code: 'M',
  transmission_name: 'MANUAL',
  upholstry_type: 'Cloth',
  interior_colour: 'Cloth Ebony',
  body_style_code: 'H',
  body_style_name: 'Hatchback',
  registration_date: '2020-02-28T00:00:00+0000',
  model_date: '2019-01-01T00:00:00+0000',
  last_odometer: 17388,
  last_odometer_date: '2021-08-12T00:00:00+0000',
  last_serviced_km: 17388,
  last_serviced: '2021-08-12T00:00:00+0000',
  whole_vehicle_warranty_included: false,
  whole_vehicle_warranty_months: undefined,
  whole_vehicle_warranty_km: 313,
  service_interval_months: 36,
  service_interval_mileage: 5000,
  whole_vehicle_warranty_expiration_date: '2020-02-29T00:00:00+0000',
  overdue: false,
  overdue_months: undefined,
  national_vehicle_test_expiry: '2023-02-29T00:00:00+0000',
  road_tax_expiry: '2023-02-01T00:00:00+0000',
  leasing_type_code: 'O01',
  leasing_type_name: 'Open Calculation',
  object_start: '2020-02-28T00:00:00+0000',
  contracted_end: '2025-02-27T00:00:00+0000',
  contracted_months: 60,
  contracted_months_run: 1,
  start_odometer: 0,
  contracted_km: 75000,
  estimated_km: 21874,
  in_cri: false,
  new_vehicle_on_order: false,
  estimated_renewal_date: '2025-02-30T00:00:00+0000',
  new_vehicle_on_order_delivery_date: undefined,
  book_value: 12374.98,
  budgeted_investment: 19607.82,
  actual_investment: 19607.82,
  monthly_depreciation: 212.3,
  included: true,
  service_start_date: '2020-02-29T00:00:00+0000',
  service_end_date: '2025-02-27T00:00:00+0000',
  product_type_label: undefined,
  company_id: 'L',
  customer_litigation: undefined,
  total_budgeted: 3592.8,
  total_cost: 2698.73,
  manufacturer: '7 FORD',
  product_type: undefined,
  replacement_vehicle_category: 'C',
  replacement_vehicle_yn: 'Y',
  vehicle_services: [
    {
      buffer: -423.29,
      service_operating_code: 580,
      service_operating_label: 'Servicing and repairs',
      service_code: LegacyVehicleServiceCode.FullService,
      service_type: 'Full Service',
    },
  ],
}

export const mockVehicleDetailsRes: VehicleDetails = {
  name: 'vehicle-details',
  description: '',
  elements: [mockVehicleDetailsItem],
}
