import { useMsal } from '@azure/msal-react'
import React, { useEffect, useState } from 'react'

import type { SilentRequest } from '@azure/msal-browser'
import type { ReactNode } from 'react'

export interface AzureAuthTemplateProps {
  azureRequest: Omit<SilentRequest, 'account'>
  children: ReactNode
  loadingComponent?: React.FC
}

/**
 * Should be used through the AuthComponent
 */
export const AzureAuthTemplate: React.FC<AzureAuthTemplateProps> = ({
  children,
  azureRequest,
  loadingComponent: LoadingComponent,
}) => {
  const { instance } = useMsal()
  const { authority } = instance.getConfiguration().auth
  const [hasAuthSession, setHasAuthSession] = useState<Boolean>()

  useEffect(() => {
    const checkAuth = async () => {
      const authRes = await instance.handleRedirectPromise()
      const activeAccount =
        authRes?.account ||
        instance.getActiveAccount() ||
        instance.getAllAccounts().find((a) => authority.includes(a.tenantId))

      if (activeAccount) {
        instance.setActiveAccount(activeAccount)
        setHasAuthSession(true)
        return
      }

      await instance.loginRedirect(azureRequest)
    }

    !hasAuthSession && checkAuth()
  }, [authority, azureRequest, hasAuthSession, instance])

  if (!hasAuthSession) {
    return LoadingComponent && <LoadingComponent />
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>
}
