import { AZURE_SYS } from '@ngb-frontend/shared/context'
import {
  SystemCode,
  type ClientConfig,
  type SharedConfig,
  type MSALClientConfig,
  type MSALEnvConfig,
} from '@ngb-frontend/shared/types'

import { createMSALClient } from '../config/createMSALClient'

import type { SilentRequest } from '@azure/msal-browser'

const baseAuthorityUrl = `login.microsoftonline.com`
const getAuthorityUrl = (
  authority = baseAuthorityUrl,
  tenantId: string,
  authorityEndpoint?: string,
) =>
  `https://${authority || baseAuthorityUrl}/${tenantId}${
    authorityEndpoint ? '/' + authorityEndpoint : ''
  }`

const sysCodeToMsalConfig: Partial<
  Record<SystemCode, keyof SharedConfig['azure']>
> = {
  [SystemCode.SFLP]: 'azureAD',
  [SystemCode.SFALD]: 'azureEntraID',
  [SystemCode.MYAY]: 'azureB2C',
}

type MSALAppConfig = {
  client: MSALClientConfig
  request: Omit<SilentRequest, 'account'>
}

export const getMSALConfig = (
  config: SharedConfig['azure'],
  systemCode: SystemCode,
): MSALAppConfig => {
  const env: MSALEnvConfig =
    config?.[sysCodeToMsalConfig[systemCode] || 'azureAD']

  const baseConfig = {
    client: {
      clientId: env.clientId,
      authority: getAuthorityUrl(
        env.authority,
        env.tenantId,
        env.authorityEndpoint,
      ),
      knownAuthorities: [env.tenantId],
    },
    request: {
      scopes: [`${env.clientId}/.default`],
    },
  }

  switch (systemCode) {
    case SystemCode.MYAY:
      return {
        client: {
          ...baseConfig.client,
          knownAuthorities: [env.authority || env.tenantId],
        },
        request: {
          scopes: [
            'openid',
            'offline_access',
            'https://aldtncstgaadb2c.onmicrosoft.com/CI-ng-booking-api/BookingApi',
          ],
          extraQueryParameters: {
            tenant: 'fi-myald-ald',
            country: 'fi',
            salesChannel: 'myald',
            partner: 'ald',
            locale: 'fi-FI',
            ui_locales: 'fi',
          },
        },
      }
    case SystemCode.SFALD:
      return {
        ...baseConfig,
        request: {
          scopes: ['User.Read'],
        },
      }
    case SystemCode.SFLP:
    default:
      return baseConfig
  }
}

export const setupMSAL = (
  config: ClientConfig['azure'],
  systemCode = SystemCode.MYLP,
) => {
  if (!systemCode || !AZURE_SYS.includes(systemCode)) return undefined

  const systemConfig = getMSALConfig(config, systemCode)
  const msalClient = createMSALClient(systemConfig.client)

  return { client: msalClient, request: systemConfig.request }
}
