import * as R from 'ramda'

import type { Address } from '@ngb-frontend/shared/types'

export const hasAddress = (x: unknown): x is Address =>
  R.allPass([
    R.pipe(R.prop('street'), R.anyPass([R.is(String), R.isNil])),
    R.pipe(R.prop('house_number'), R.anyPass([R.is(String), R.isNil])),
    R.pipe(R.prop('postcode'), R.anyPass([R.is(String), R.isNil])),
    R.pipe(R.prop('city'), R.anyPass([R.is(String), R.isNil])),
  ])(x)
