import * as R from 'ramda'
import React from 'react'

import { staticInspections } from '@ngb-frontend/content'
import { getInspectionStepDataFromBooking } from '@ngb-frontend/shared/utils'

import { InspectionForm } from '../../forms/InspectionForm/InspectionForm'

import type {
  InspectionFormValues,
  InspectionStepPrerequisiteData,
  InspectionStepValues,
  StepProps,
} from '@ngb-frontend/shared/types'

const InspectionStep: React.FC<
  StepProps<InspectionStepValues, InspectionStepPrerequisiteData>
> = ({ onNextStep, onUpdateStep, stepData, booking }) => {
  const initialValuesRef = React.useRef<InspectionStepValues | undefined>(
    hasInspectionFormValues(stepData)
      ? { inspection: stepData.inspection }
      : booking
      ? getInspectionStepDataFromBooking(booking)
      : undefined,
  )

  const handleSubmit = React.useCallback(
    (values: InspectionFormValues) => {
      onUpdateStep(values)
      onNextStep()
    },
    [onNextStep, onUpdateStep],
  )

  return (
    <InspectionForm
      inspections={staticInspections}
      onSubmit={handleSubmit}
      initialValues={initialValuesRef.current}
    />
  )
}

export const hasInspectionFormValues = (x: unknown) =>
  R.allPass([R.pipe(R.prop('inspection'), R.is(String))])(x)

export default InspectionStep
