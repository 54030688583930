import { addMonths } from 'date-fns'

import type { VehicleObject } from '@ngb-frontend/shared/types'

export const motEndDate = addMonths(new Date(), 1).toISOString()

const leaseObjectId = '3fa85f64-5717-4562-b3fc-2c963f66afa6'
const creationDate = '2021-08-26T06:59:12.696Z'

export const mockVehicleObject: VehicleObject = {
  lease_object_id: leaseObjectId,
  uin: '3Y1SL65848Z411439',
  registration_number: 'EK 5699',
  object_type: 'vehicle',
  make_local: 'Ford',
  make_global: 'Ford',
  model_local: '1.5 EcoBlue TDi',
  model_global: '1.5 EcoBlue TDi',
  local_version_name: 'string',
  lp_entity_code: 'LPUK',
  is_migrated: true,
  migrated_object_id: 'string',
  supplier: 'string',
  data_supplier: 'JATO',
  data_supplier_code: 'A123',
  data_supplier_object_id: 'A123',
  registration_location: 'string',
  restricted_access: 'string',
  registration_date: '2021-08-26',
  first_registration_date: '2021-08-26',
  is_custom_registration_number: false,
  vat_status: 'string',
  insurance_group: 'string',
  status: 'OrderCancelled',
  external_configuration_hash: 'string',
  manufacture_date: '2019-08-26',
  number_of_doors: 0,
  number_of_gears: 0,
  number_of_seats: 0,
  transmission_type: 'string',
  combined_powertrain_and_energy_type: 'string',
  powertrain: 'string',
  energy_type: 'string',
  engine_power_kw: 0,
  co2_emission: 0,
  co2_emission_nedc: 0,
  co2_emission_wltp: 0,
  energy_consumption: 0,
  engine_capacity: 0,
  engine_capacity_cc: 0,
  engine_number: 'string',
  key_number: 'string',
  radio_code: 'string',
  model_year: 'string',
  model_generation: 'string',
  body_type: 'string',
  vehicle_type: 'string',
  euro_emission_standard: 'string',
  driven_wheels: 'string',
  fuel_tank_capacity: 0,
  number_of_cylinders: 0,
  government_grant_amount: 0,
  gross_trailer_weight_braked: 0,
  gross_trailer_weight_unbraked: 0,
  gross_vehicle_weight: 0,
  load_capacity: 0,
  kerb_weight: 0,
  height: 0,
  length: 0,
  width: 0,
  trim_level_global: 'string',
  paint_type: 'string',
  tyre_rim_diameter_rear: 'string',
  tyre_size_front: 'string',
  tyre_size_rear: 'string',
  rim_material: 'string',
  service_interval_months: 36,
  service_interval_type_name: 'string',
  service_interval_mileage: 5000,
  oem_warranty_duration: 'string',
  oem_warranty_start_date: '2021-10-26',
  oem_warranty_end_date: '2022-10-26',
  oem_warranty_max_mileage: 20000,
  trim_level_local: 'string',
  mot_expiry_date: motEndDate,
  first_year_road_tax_amount: 0,
  subsequent_year_road_tax_amount: 0,
  road_tax_due_date: '2024-01-15',
  vehicle_image_link: 'string',
  creation_date: creationDate,
  last_update_date: creationDate,
  vehicle_price: {
    currency: 'string',
    consumer_price: 0,
    base_price: 0,
    national_tax_vat_percentage: 0,
    delivery_charge_excluding_vat: 0,
    creation_date: creationDate,
    last_update_date: creationDate,
    vehicle_discounts: [
      {
        type: 'string',
        method: 'string',
        amount: 0,
        amount_including_vat: 0,
        percentage: 0,
        creation_date: creationDate,
        last_update_date: creationDate,
      },
    ],
  },
  options: [
    {
      external_option_id: 'string',
      manufacturer_code: 'string',
      name: 'string',
      description: 'string',
      serial_number: 'string',
      option_type: 'string',
      supplier: 'string',
      creation_date: creationDate,
      last_update_date: creationDate,
      option_price: {
        currency: 'string',
        vat_code: 'string',
        base_cost_amount: 0,
        discount_manufacturer_amount: 0,
        discount_manufacturer_percentage: 0,
        discount_dealer_amount: 0,
        discount_dealer_percentage: 0,
        discount_total_amount: 0,
        discount_total_percentage: 0,
        creation_date: creationDate,
        last_update_date: creationDate,
        option_discounts: [
          {
            type: 'string',
            method: 'string',
            amount: 0,
            amount_including_vat: 0,
            percentage: 0,
            creation_date: creationDate,
            last_update_date: creationDate,
          },
        ],
      },
    },
  ],
  tyres: [
    {
      position: 'string',
      make: 'string',
      model: 'string',
      dot_code: 'string',
      profile: 'string',
      type: 'string',
      tread: 'string',
      creation_date: creationDate,
      last_update_date: creationDate,
    },
  ],
  mileage_history: [
    {
      mileage: 5000,
      unit: 'kilometers',
      report_date: creationDate,
      source: 'Crims',
      source_type: 'Tyres',
      creation_date: creationDate,
      last_update_date: creationDate,
    },
    {
      mileage: 10000,
      unit: 'kilometers',
      report_date: creationDate,
      source: 'Sims',
      source_type: 'Maintenance',
      creation_date: creationDate,
      last_update_date: creationDate,
    },
    {
      mileage: 15000,
      unit: 'miles',
      report_date: creationDate,
      source: 'Nols',
      source_type: 'Ngda',
      creation_date: creationDate,
      last_update_date: creationDate,
    },
  ],
}
