import { makeStyles } from '@material-ui/core'

import type { Theme } from '@material-ui/core'

type Props = { row?: boolean }

export const useMalfunctionsFieldStyles = makeStyles<Theme, Props>(() => ({
  accordion: {
    marginTop: ({ row }: Props) => (row ? 0 : 'var(--vel-fixed-spacing-05)'),
  },
  checkboxCardGroup: {
    '& > div': {
      width: '100%',
      '& label': {
        width: '100%',
      },
    },
  },
}))
