import * as R from 'ramda'
import React from 'react'

import { AdditionalInformationForm } from '../../forms/AdditionalInformationForm/AdditionalInformationForm'

import type {
  AdditionalInformationFormValues,
  AdditionalInformationStepPrerequisiteData,
  AdditionalInformationStepValues,
  StepProps,
} from '@ngb-frontend/shared/types'

const AdditionalInformationStep: React.FC<
  StepProps<
    AdditionalInformationStepValues,
    AdditionalInformationStepPrerequisiteData
  >
> = ({ onNextStep, onUpdateStep, stepData }) => {
  const initialValuesRef = React.useRef<
    AdditionalInformationFormValues | undefined
  >(
    hasAdditionalInformationFormValues(stepData)
      ? {
          additionalInformation: stepData.additionalInformation,
        }
      : undefined,
  )

  const handleSubmit = React.useCallback(
    (values: AdditionalInformationFormValues) => {
      onUpdateStep(values)
      onNextStep()
    },
    [onNextStep, onUpdateStep],
  )

  return (
    <AdditionalInformationForm
      onSubmit={handleSubmit}
      initialValues={initialValuesRef.current}
    />
  )
}

export const hasAdditionalInformationFormValues = (
  x: unknown,
): x is AdditionalInformationFormValues =>
  R.allPass([R.pipe(R.prop('additionalInformation'), R.is(String))])(x)

export default AdditionalInformationStep
