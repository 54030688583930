import * as R from 'ramda'

import { hasAddress } from '../typeguards/hasAddress'

import type { MaintenanceAndRepairFormValues } from '@ngb-frontend/shared/types'

export const hasMaintenanceAndRepairFormValues = (
  x: unknown,
): x is MaintenanceAndRepairFormValues =>
  // TODO: needs to be more strict
  R.allPass([
    R.pipe(R.prop('mileage'), R.is(Number)),
    R.pipe(R.prop('contactType'), R.is(String)),
    R.pipe(
      R.prop('contact'),
      R.allPass([
        R.pipe(R.prop('firstName'), R.is(String)),
        R.pipe(R.prop('lastName'), R.is(String)),
        R.pipe(R.prop('email'), R.is(String)),
        R.pipe(R.prop('phone'), R.is(String)),
      ]),
    ),
    R.pipe(R.prop('dateTime'), R.is(String)),
    R.pipe(R.prop('location'), R.is(String)),
    R.pipe(R.prop('address'), hasAddress),
    R.pipe(R.prop('services'), R.is(Array)),
    R.pipe(R.prop('extraServices'), R.is(Array)),
  ])(x)
