import { ChevronLeftIcon, CloseIcon } from '@velocity/icons/system'
import { ProgressBar, Headline, ActionButton, Text } from '@velocity/ui'
import { FlexBox, FlexColumn, TopNavigation } from '@velocity/ui/draft'
import { useCallback, useState } from 'react'

import { useContent } from '@ngb-frontend/content'
import { useCleanup } from '@ngb-frontend/shared/utils'

import { useNavigationBarStyles } from './NavigationBar.styled'
import { StopBookingModal } from '../StopBookingModal/StopBookingModal'

interface NavigationBarProps {
  label?: string
  topLabel?: string
  onStepBack?: () => void
  onClose?: () => void
  progress?: number
  showLogo?: boolean
}

export const NavigationBar: React.FC<NavigationBarProps> = ({
  onStepBack,
  onClose,
  label,
  topLabel,
  progress,
  showLogo,
}) => {
  const classes = useNavigationBarStyles({ showLogo })
  const c = useContent()
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const { clearLocalStorage, clearSWRCache } = useCleanup()
  const handleOnClose = useCallback(() => {
    clearLocalStorage({ exclude: ['swrCache'] })
    clearSWRCache()
    onClose?.()
  }, [clearLocalStorage, clearSWRCache, onClose])

  return (
    <FlexBox className={classes.navWrapper}>
      <TopNavigation layoutContainerSize="fluid">
        <TopNavigation.Logo className={classes.logo} />
        <TopNavigation.Widgets>
          {onClose && (
            <StopBookingModal
              open={modalIsOpen}
              onContinue={() => setModalIsOpen(false)}
              onStop={handleOnClose}
            />
          )}
          <FlexBox className={classes.root}>
            <ActionButton
              className={classes.button}
              style={{ display: onStepBack ? 'block' : 'none' }}
              data-e2e-component="back-button"
              onClick={() => onStepBack?.()}
              Icon={ChevronLeftIcon}
              title={c('steps.shared.back')}
              aria-label={c('steps.shared.back')}
            />
            {label && (
              <FlexColumn>
                {topLabel && (
                  <Text variant="100" className={classes.progressText}>
                    {topLabel}
                  </Text>
                )}
                <Headline className={classes.label} variant="100">
                  {label}
                </Headline>
              </FlexColumn>
            )}
            <ActionButton
              className={classes.button}
              style={{ visibility: onClose ? 'visible' : 'hidden' }}
              data-e2e-component="close-button"
              onClick={() => setModalIsOpen(true)}
              Icon={CloseIcon}
              title={c('steps.shared.close')}
              aria-label={c('steps.shared.close')}
            />
          </FlexBox>
          {progress !== undefined && (
            <ProgressBar
              className={classes.progressBar}
              aria-label={c('steps.shared.bookingProgress')}
              percent={progress}
              variant="secondary"
            />
          )}
        </TopNavigation.Widgets>
      </TopNavigation>
    </FlexBox>
  )
}
