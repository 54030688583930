import { List, NotificationBanner } from '@velocity/ui'

import { useContent } from '@ngb-frontend/content'
import { LinkInline } from '@ngb-frontend/shared/ui'

const prefix = 'steps.contact.notification'
const options = ['changePhone', 'changeEmail']

export const ContactNotificationBanner: React.FC = () => {
  const c = useContent()

  return (
    <NotificationBanner
      kind="warning"
      title={c(`${prefix}.title`)}
      data-e2e-component="contact-notification-banner"
    >
      <List>
        {options.map((key) => (
          <List.Item key={key}>
            <span>
              {c.rich(`${prefix}.options.${key}`, {
                variables: {
                  link: (chunks) => (
                    <LinkInline
                      target="_blank"
                      href={c(`${prefix}.links.${key}`)}
                    >
                      {Array.isArray(chunks) ? chunks.join() : chunks}
                    </LinkInline>
                  ),
                },
              })}
            </span>
          </List.Item>
        ))}
      </List>
    </NotificationBanner>
  )
}
