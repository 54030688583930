import { ChevronDownIcon, ChevronUpIcon } from '@velocity/icons/system'
import { Headline, Radio, Card } from '@velocity/ui'
import { type MouseEvent, useCallback, useState } from 'react'

import { useRadioCardStyles } from './RadioCard.styled'

import type { RadioCardProps } from '@ngb-frontend/shared/types'

export const RADIO_DATA_ID_ATTRIBUTE = 'radio-card-id'

export const RadioCard = <T extends string>({
  id,
  name = 'radio-card',
  tooltip,
  title,
  content,
  collapsedContent,
  disableCheck,
  checked,
  borderColor,
  onChange,
  hideCollapseIndicator = false,
  disabled,
}: React.PropsWithChildren<RadioCardProps<T>>) => {
  const [isCollapsed, setIsCollapsed] = useState(true)
  const classes = useRadioCardStyles({ checked, borderColor })

  const handleToggleCollapse = useCallback((e: MouseEvent) => {
    e.stopPropagation()
    setIsCollapsed((prev) => !prev)
  }, [])

  const handleChange = useCallback(() => {
    onChange(id)
  }, [id, onChange])

  return (
    <div
      onClick={handleChange}
      className={classes.root}
      data-e2e-component={name}
      {...{ [RADIO_DATA_ID_ATTRIBUTE]: id }}
    >
      <Card>
        <Card.Body>
          <div className={classes.content}>
            {tooltip && <div className={classes.tooltip}>{tooltip}</div>}
            {!disableCheck && (
              <div className={classes.radioContainer}>
                {/* Replace with RadioButtonCard */}
                <Radio
                  disabled={disabled}
                  name={name}
                  checked={checked}
                  aria-label={id}
                />
              </div>
            )}
            <div
              className={classes.textContainer}
              data-e2e-component="radio-card-content"
            >
              {typeof title === 'string' ? (
                <Headline variant="100" withMarginBottom>
                  {title}
                </Headline>
              ) : (
                title
              )}
              {content}
            </div>
            {!hideCollapseIndicator && (
              <div
                className={classes.buttonContainer}
                onClick={handleToggleCollapse}
                data-e2e-component="radio-card-collapse-indicator"
              >
                {isCollapsed ? (
                  <ChevronDownIcon size="m" color="secondary" />
                ) : (
                  <ChevronUpIcon size="m" color="secondary" />
                )}
              </div>
            )}
          </div>
          {collapsedContent && !isCollapsed && (
            <div
              className={classes.collapsedContent}
              data-e2e-component="radio-card-collapsed-content"
            >
              {collapsedContent}
            </div>
          )}
        </Card.Body>
      </Card>
    </div>
  )
}
