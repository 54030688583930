import { LinkInline as VelocityLinkInline } from '@velocity/ui'
import clsx from 'clsx'

import { useLinkInlineStyles } from './LinkInline.styled'

import type { SystemIcon } from '@velocity/icons/system'
import type {
  LinkInlineProps as VelocityLinkInlineProps,
  ButtonProps,
  TLinkTagOrComponent,
} from '@velocity/ui'

interface LinkInlineProps {
  StartIcon?: SystemIcon
  EndIcon?: SystemIcon
  size?: ButtonProps['size']
}

const iconSizeMap = {
  s: 'xs',
  m: 's',
  l: 'm',
}

export function LinkInline<T extends TLinkTagOrComponent = 'a'>({
  children,
  StartIcon,
  EndIcon,
  size = 's',
  className,
  ...rest
}: React.PropsWithChildren<VelocityLinkInlineProps<T> & LinkInlineProps>) {
  const classes = useLinkInlineStyles({ size })
  return (
    // @ts-ignore
    <VelocityLinkInline {...rest} className={clsx(classes.root, className)}>
      {StartIcon && (
        <span className={classes.startIcon} data-e2e-component="start-icon">
          {/* @ts-ignore */}
          <StartIcon size={iconSizeMap[size]} />
        </span>
      )}
      <span>{children}</span>
      {EndIcon && (
        <span className={classes.endIcon} data-e2e-component="end-icon">
          {/* @ts-ignore */}
          <EndIcon size={iconSizeMap[size]} />
        </span>
      )}
    </VelocityLinkInline>
  )
}
