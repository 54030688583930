import { defaultLocale } from '@ngb-frontend/content'

export const localeToCountryISOCode = (locale: Intl.Locale['baseName']) => {
  try {
    const countryCode = new Intl.Locale(locale).region
    return countryCode
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(`Invalid locale: ${locale}, falling back to ${defaultLocale}`)
    return new Intl.Locale(defaultLocale).region
  }
}
