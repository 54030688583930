/**
 * The contents of this file are manual helpers for the Contract.services.universal_service_code (string) type
 */

export enum UniversalMnRServiceCode {
  RegularMaintenance = 'SVC-RMSCHED',
  Repair = 'SVC-RMMCHNL',
  MOT = 'SVC-RMLEGAL',
}

export enum UniversalEoLServiceCode {
  EOL = 'SVC-VHCINTK',
}

export enum UniversalRPVServiceCode {
  RPVRegularMaintenance = 'SVC-RPVSCHD',
  RPVDamage = 'SVC-RPVDMGE',
  RPVMOT = 'SVC-RPVLEGL',
  RPVRepair = 'SVC-RPVMCHL',
  RPVRecall = 'SVC-RPVRCAL',
  RPVTheft = 'SVC-RPVTHFT',
  RPVTyres = 'SVC-RPVTYRS',
}

export const UniversalServiceCode = {
  ...UniversalMnRServiceCode,
  ...UniversalEoLServiceCode,
  ...UniversalRPVServiceCode,
}

export type UniversalServiceCode =
  | UniversalMnRServiceCode
  | UniversalEoLServiceCode
  | UniversalRPVServiceCode
