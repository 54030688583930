import { makeStyles } from '@material-ui/core'

import type { Theme } from '@material-ui/core'

interface Props {
  disabled?: boolean
}

export const useCardButtonStyles = makeStyles<Theme, Props>(() => ({
  root: {
    cursor: (props: Props) => (props.disabled ? 'auto' : 'pointer'),
    marginBottom: 'var(--vel-fixed-spacing-05)',
    filter: (props: Props) =>
      props.disabled ? 'grayscale(1) opacity(0.7)' : 'none',
  },
  content: () => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    wordBreak: 'break-word',
  }),
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'var(--vel-fixed-spacing-07)',
  },
  badge: {
    marginBottom: 'var(--vel-fixed-spacing-04)',
  },
  label: () => ({
    position: 'absolute',
    top: 0,
    right: 0,
    background: 'var(--vel-color-primary-400)',
    color: 'var(--vel-color-neutral-white)',
    padding:
      'var(--vel-fixed-spacing-02) var(--vel-fixed-spacing-03) var(--vel-fixed-spacing-02) var(--vel-fixed-spacing-05)',
    borderRadius: 'var(--vel-fixed-spacing-02)',
    borderBottomRightRadius: 0,
    fontSize: '0.625rem',
    fontWeight: 'bold',
    '&:after': {
      content: '""',
      position: 'absolute',
      display: 'block',
      background: 'inherit',
      width: 16,
      left: -4,
      bottom: 0,
      top: 0,
      transform: 'skewX(15deg)',
      transformOrigin: '100%',
      borderRadius: 'var(--vel-fixed-spacing-02)',
      borderTopLeftRadius: 0,
    },
  }),
}))
