import type {
  AdditionalServiceValue,
  Service,
  ServiceDictionary,
  ServiceItem,
} from '@ngb-frontend/shared/types'

export const services: ServiceDictionary = {
  services: ['RegularMaintenance', 'Repair', 'MOT'],
  'extra-services': ['CollectAndReturn', 'ReplacementVehicle'],
}

export const mockEnabledServices = services.services.reduce(
  (acc, curr) => ({
    ...acc,
    [curr]: true,
  }),
  {},
)

export const mockEnabledExtraServices = services['extra-services'].reduce(
  (acc, curr) => ({
    ...acc,
    [curr]: true,
  }),
  {},
)

export const mockServiceItem: ServiceItem<AdditionalServiceValue> = {
  code: 'CollectAndReturn',
  value: 'CollectAndReturn',
}

export const mockService: Service = {
  description:
    'I need help with my car\n\nAlternative date 1: 22 Feb 2022 - 22:22\nAlternative date 2: 11 Feb 2011 - 11:11',
  items: [
    {
      code: 'Repair',
      value: 'Repair',
    },
  ],
}
