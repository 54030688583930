import { setupServer } from 'msw/node'

import type {
  RestHandler,
  MockedRequest,
  DefaultRequestBody,
  SharedOptions,
} from 'msw'

export const initServerApiMocks = (
  handlers: RestHandler<MockedRequest<DefaultRequestBody>>[],
  options?: SharedOptions | undefined,
) => {
  const server = setupServer(...handlers)
  server.listen(options)
  return server
}
