import * as R from 'ramda'
import React from 'react'

import {
  getMileageStepDataFromBooking,
  useVehicle,
  useQuery,
} from '@ngb-frontend/shared/utils'

import { MileageForm } from '../../forms/MileageForm/MileageForm'

import type { MileageFormValues, StepProps } from '@ngb-frontend/shared/types'

const MileageStep: React.FC<StepProps<MileageFormValues>> = ({
  onNextStep,
  onUpdateStep,
  stepData,
  booking,
}) => {
  const query = useQuery()
  const { data: vehicle } = useVehicle({
    // Always pass an ilan and/or licensePlate to this hook call to enable app state overrides
    vehicleIdentifier: query?.vehicleIdentifier,
    country: query?.country,
  })

  const initialValuesRef = React.useRef<MileageFormValues | undefined>(
    hasMileageFormValues(stepData)
      ? { mileage: stepData.mileage }
      : booking
      ? getMileageStepDataFromBooking(booking)
      : vehicle?.mostRecentMileage
      ? { mileage: vehicle?.mostRecentMileage }
      : undefined,
  )

  const handleSubmit = React.useCallback(
    (values: MileageFormValues) => {
      onUpdateStep(values)

      onNextStep()
    },
    [onNextStep, onUpdateStep],
  )

  return (
    <MileageForm
      onSubmit={handleSubmit}
      initialValues={initialValuesRef.current}
    />
  )
}

export const hasMileageFormValues = (x: unknown): x is MileageFormValues =>
  R.allPass([R.pipe(R.prop('mileage'), R.is(Number))])(x)

export default MileageStep
