import { getDateFnsLocale, type DateFnsLocale } from './dateFnsLocales'

import type { CountryCode } from '@ngb-frontend/shared/types'

export const defaultLPCountry: CountryCode = 'LPUK'

type CountryL10n = Record<
  `${CountryCode}`,
  { localeId: DateFnsLocale; tz: string }
>

export const countryL10n: CountryL10n = {
  [defaultLPCountry]: { localeId: 'enGB', tz: 'Europe/London' },
  '5601': { localeId: 'fr', tz: 'Europe/Brussels' },
  '25001': { localeId: 'fr', tz: 'Europe/Paris' },
  '25002': { localeId: 'fr', tz: 'Europe/Paris' },
  '25003': { localeId: 'fr', tz: 'Europe/Paris' },
  '6420': { localeId: 'ro', tz: 'Europe/Bucharest' },
  '48401': { localeId: 'es', tz: 'America/Mexico_City' },
  '75201': { localeId: 'sv', tz: 'Europe/Stockholm' },
  '75202': { localeId: 'sv', tz: 'Europe/Stockholm' },
  '75601': { localeId: 'frCH', tz: 'Europe/Stockholm' },
  '4001': { localeId: 'deAT', tz: 'Europe/Vienna' },
}

export const getCountryL10nCtx = (country?: CountryCode) => {
  const conf = countryL10n[country || defaultLPCountry]
  const dateFnsLocale = getDateFnsLocale(conf.localeId)
  return {
    ...conf,
    country,
    locale: { [conf.localeId]: dateFnsLocale },
  }
}
