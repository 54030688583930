import { lazy, Suspense } from 'react'

import { FullScreenLoader } from '@ngb-frontend/shared/ui'

import type {
  EndOfLeaseStepValues,
  StepComponentContainer,
} from '@ngb-frontend/shared/types'

const EndOfLeaseStepLazy = lazy(() => import('./EndOfLeaseStep'))

export const EndOfLeaseStepContainer: StepComponentContainer<
  EndOfLeaseStepValues
> = (props) => {
  return (
    <Suspense fallback={<FullScreenLoader />}>
      <EndOfLeaseStepLazy {...props} />
    </Suspense>
  )
}

EndOfLeaseStepContainer.areStepPrerequisitesMet = (
  _mergedFlowData,
): _mergedFlowData is {} => true
