export enum ContactType {
  Driver = 'driver',
  Other = 'other',
}

export type OtherContactDetails = {
  contact_name: string
  contact_email?: string
  contact_phone: string
}

export type ContactFormValues = {
  firstName: string
  lastName: string
  email: string
  phone: string
}
