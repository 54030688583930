import { addMonths } from 'date-fns'

import {
  type LeaseChange,
  LeaseChangeType,
  LeaseChangeCategory,
  LeaseChangeStatus,
} from '@ngb-frontend/shared/types'

import { mockContract } from './contract'

export const mockLeaseChange: LeaseChange = {
  status: LeaseChangeStatus.PendingInProgress,
  case_id: 'MLC-91023',
  child_case_id: null,
  external_case_id: null,
  individual_lease_agreement_number:
    mockContract.individual_lease_agreement_number,
  source_contract_version: 2,
  destination_contract_version: null,
  request_date: '2023-03-10',
  status_change_date: '2023-03-10T07:17:17.747Z',
  quote_validity_date: addMonths(new Date(), 2).toISOString(),
  effective_from_date: '2022-07-01',
  go_live_date: '2023-04-01',
  reason: null,
  user: 'john.doe@leaseplan.com',
  category: LeaseChangeCategory.EoC,
  types: [
    {
      type: LeaseChangeType.VehicleReturn,
    },
  ],
}

export const mockLeaseChanges: LeaseChange[] = (
  Object.values(LeaseChangeStatus) as Array<LeaseChangeStatus>
).map((status, idx) => ({
  ...mockLeaseChange,
  case_id: mockLeaseChange.case_id || '' + idx,
  status,
}))
