import { makeStyles } from '@material-ui/core'
import { mq } from '@velocity/styling'

import type { Theme } from '@material-ui/core'

type Props = {
  hideOptionalLabel: boolean
  noMargin?: boolean
  disabled: boolean
}

export const useFormFieldStyles = makeStyles<Theme, Props>(() => ({
  root: {
    margin: ({ noMargin }) =>
      noMargin ? 0 : '0 0 var(--vel-fixed-spacing-07) 0',
  },
  field: {
    '&&': {
      flexGrow: 1,
    },
    '& + &': {
      marginTop: 'var(--vel-fixed-spacing-07)',
      [mq.min('MD')]: {
        marginTop: 0,
      },
    },
    '& [id*="_label"] [aria-hidden="true"]': {
      display: ({ hideOptionalLabel }: Props) =>
        hideOptionalLabel ? 'none' : 'block',
    },
    '&& input': {
      color: ({ disabled }: Props) =>
        disabled ? 'var(--vel-color-neutral-700)' : 'currentColor',
    },
  },
  hint: {
    '&&': {
      marginTop: 'var(--vel-fixed-spacing-02)',
    },
  },
  optional: {
    color: 'var(--vel-color-neutral-600)',
  },
}))
