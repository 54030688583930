import { FormField as VelocityFormField, Text } from '@velocity/ui'
import clsx from 'clsx'
import { type ReactElement, type ReactNode } from 'react'
import { Field } from 'react-final-form'

import { useContent } from '@ngb-frontend/content'
import { scrollToErrorClass } from '@ngb-frontend/shared/utils'

import { useFormFieldStyles } from './FormField.styled'

import type { FormFieldStyles } from '@ngb-frontend/shared/types'
import type { FieldValidator } from 'final-form'
import type { FieldMetaState, FieldInputProps } from 'react-final-form'

export interface FormFieldProps<T = string> {
  /**
   * An custom id to be used for VUI formfield's children
   */
  id?: string
  /**
   * A key to manually trigger field rerenders (rerun validations etc...)
   */
  renderKey?: string
  /**
   * Value for the name attribute of the underlying HTML input.
   */
  name: string
  /**
   * Optional label for the form field.
   */
  label?: ReactNode
  /**
   * Optional hideLabel prop.
   */
  hideLabel?: boolean
  /**
   * Optional HTML for value for the form field.
   */
  htmlFor?: string
  /**
   * Optional FieldValidator for the form field.
   */
  validator?: FieldValidator<T>
  /**
   * Optional Form Field Styles to set the styling of the form control and form label of the field.
   */
  classes?: FormFieldStyles
  /**
   * Optional noMargin when margin should not be applied to the field container
   */
  noMargin?: boolean
  /**
   * Optional noValidation attribute for conditional validations
   */
  noValidation?: boolean
  /**
   * Children as function to render your inputs.
   * @param fieldProps
   */
  children: (fieldProps: {
    input: FieldInputProps<T, HTMLElement>
    meta: FieldMetaState<T>
  }) => ReactElement | ReactElement[]
  /**
   * A hint to display below the field label.
   */
  hint?: string
  /**
   * In case of a Checkbox/RadioGroup, or multiple inputs, group should be set to true.
   */
  group?: true
  /**
   * Optional Required for the form field.
   */
  required?: boolean
  /**
   * Optional disabled for the form field.
   */
  disabled?: boolean
  /**
   * Optional hideOptionalLabel for the form field.
   */
  hideOptionalLabel?: boolean
  /**
   * Optional Type for the form field used by react final form to determine field type.
   */
  type?: string
  /**
   * A custom show error function
   */
  showErrorFn?: <TFieldValue>(
    meta: FieldMetaState<TFieldValue>,
  ) => string | null | undefined
}

function showErrorFnDefaultFactory<TFieldValue>() {
  return (meta: FieldMetaState<TFieldValue>) =>
    meta.error && meta.touched ? meta.error.message : null
}

export const FormField: <T>(props: FormFieldProps<T>) => ReactElement = ({
  name,
  label = ' ',
  hideLabel,
  htmlFor,
  validator,
  children,
  classes,
  renderKey,
  hint,
  showErrorFn,
  group,
  id,
  hideOptionalLabel = false,
  required = true,
  type,
  noMargin,
  noValidation = false,
  disabled = false,
}) => {
  const ownClasses = useFormFieldStyles({
    hideOptionalLabel,
    noMargin,
    disabled,
  })
  const showError = showErrorFn || showErrorFnDefaultFactory()
  const c = useContent()

  return (
    <Field
      name={name}
      type={type}
      /**
       * Based on https://final-form.org/docs/react-final-form/types/FieldProps#validate
       * Known error in the first key change
       * https://github.com/final-form/react-final-form/issues/850
       */
      validate={noValidation ? undefined : validator}
      key={renderKey || (noValidation ? 1 : undefined)}
      /* The parse function ensures form values never get set to null,
       * but rather, empty strings.
       *
       * See https://github.com/final-form/react-final-form/issues/130
       */
      parse={(value) => value}
    >
      {({ input, meta }) => (
        <div
          className={clsx(ownClasses.root, meta.error && scrollToErrorClass)}
        >
          {hideLabel && !hideOptionalLabel && !required && (
            <Text variant="100" className={ownClasses.optional}>
              {c('fields.shared.optional')}
            </Text>
          )}
          <VelocityFormField
            id={id}
            errorMessage={showError(meta)}
            label={label}
            hideLabel={hideLabel}
            className={clsx(classes?.formControl, ownClasses.field)}
            {...(group ? { group: true } : { inputId: htmlFor })}
            required={required}
          >
            {children({ input, meta })}
          </VelocityFormField>
          {hint && (
            <Text variant="100" className={ownClasses.hint}>
              {hint}
            </Text>
          )}
        </div>
      )}
    </Field>
  )
}
