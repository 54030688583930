import { makeStyles } from '@material-ui/core'

import type { Theme } from '@material-ui/core'

export const useAdditionalInformationFormStyles = makeStyles(
  (theme: Theme) => ({
    field: {
      '&&': {
        minHeight: theme.spacing(23),
      },
    },
  }),
)
