import { Grid, NotificationBanner, Text } from '@velocity/ui'

import { scrollToErrorClass } from '@ngb-frontend/shared/utils'

import type { FormFieldWrapper } from '@ngb-frontend/shared/types'
import type { PropsWithChildren } from 'react'

export const FormFieldGroupWrapper: React.FC<
  Pick<FormFieldWrapper, 'label' | 'error' | 'dirty' | 'hint'> &
    PropsWithChildren
> = ({ label, error, hint, children, dirty }) => {
  return (
    <div
      className={error?.message ? scrollToErrorClass : undefined}
      data-e2e-component="form-field-group-wrapper"
    >
      {label && (
        <Text variant="100" bold margin={{ bottom: '02' }}>
          {label}
        </Text>
      )}
      {children}
      {hint && (
        <Text variant="100" margin={{ top: '02' }}>
          {hint}
        </Text>
      )}
      {dirty && error?.message && (
        <Grid margin={{ top: '02' }}>
          <Grid.Item LG={6}>
            <NotificationBanner
              kind="error"
              data-e2e-component="form-field-group-wrapper-error"
            >
              {error.message}
            </NotificationBanner>
          </Grid.Item>
        </Grid>
      )}
    </div>
  )
}
