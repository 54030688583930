import { Headline } from '@velocity/ui'
import { FlexColumn } from '@velocity/ui/draft'
import React from 'react'

import { useStepLayoutStyles } from './StepLayout.styled'
import { Banner } from '../Banner/Banner'
import { Footer } from '../Footer/Footer'
import { NavigationBar } from '../NavigationBar/NavigationBar'

import type { StepLayoutProps } from '@ngb-frontend/shared/types'

export const StepLayout: React.FC<StepLayoutProps> = ({
  navLabel,
  title,
  banner,
  progress,
  onStepBack,
  onClose,
  children,
  topNavLabel,
  showLogo,
  fullWidth = false,
}) => {
  const classes = useStepLayoutStyles({ fullWidth, showLogo })

  return (
    <FlexColumn className={classes.root}>
      <NavigationBar
        label={navLabel}
        onStepBack={onStepBack}
        onClose={onClose}
        progress={progress}
        topLabel={topNavLabel}
        showLogo={showLogo}
      />
      <div className={classes.container}>
        {banner && <Banner title={banner.title} image={banner.image} />}
        <div className={classes.content}>
          <div className={classes.inner}>
            {title && (
              <Headline
                variant="300"
                className={classes.title}
                data-e2e-component="step-layout-title"
              >
                {title}
              </Headline>
            )}
            {children}
          </div>
        </div>
        <Footer />
      </div>
    </FlexColumn>
  )
}
