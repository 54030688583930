import {
  type Booking,
  type ServiceFormValues,
  type InspectionCode,
  type ReplacementVehicleStepValues,
  type SelectServiceLocationStepValues,
  type LocationStepValues,
  type ContactStepValues,
  type ContactFormValues,
  ContactType,
} from '@ngb-frontend/shared/types'

import {
  parseDriverContact,
  parseOtherContact,
} from '../object/converters/getContact'
import { groupMalfunctionCodes } from '../object/malfunctions'

export const getMileageStepDataFromBooking = (booking: Booking) => ({
  mileage: booking.mileage,
})

export const getServiceStepDataFromBooking = (
  booking: Booking,
  variant: keyof ServiceFormValues,
) => {
  const isExtraServices = variant === 'extraServices'

  const variantServices = isExtraServices
    ? booking.additional_services
    : booking.service?.items

  return {
    [isExtraServices ? 'extraServices' : 'services']:
      variantServices?.map((s) => s.code) || [],
  }
}

export const getMalfunctionStepDataFromBooking = (booking: Booking) => ({
  malfunctions: groupMalfunctionCodes(booking.malfunctions),
  malfunctionDescription: booking.malfunction_description,
})

export const getInspectionStepDataFromBooking = (booking: Booking) => ({
  inspection: booking.mot_type as InspectionCode,
})

export const getReplacementVehicleStepDataFromBooking = (
  booking: Booking,
): ReplacementVehicleStepValues => ({
  replacementVehicle: !!(
    booking.replacement_mobility === 'RentalByExternalCompany'
  ),
  courtesyVehicle: !!(booking.replacement_mobility === 'Courtesy'),
})

export const getSelectServiceLocationStepDataFromBooking = (
  booking: Booking,
): SelectServiceLocationStepValues => ({
  supplier: booking.supplier,
})

export const getLocationStepDataFromBooking = (
  booking: Booking,
): LocationStepValues => ({
  location: booking.driver.pickup_address,
})

export const getContactStepDataFromBooking = (
  booking: Booking,
): ContactStepValues => {
  const driver = parseDriverContact(booking.driver)
  const other = parseOtherContact(booking.contact_details || {})
  const hasOther = booking.contact_details?.contact_email
  const isEqual = Object.entries(driver).every(
    ([key, value]) => other[key as keyof ContactFormValues] === value,
  )

  return !hasOther || isEqual
    ? {
        contactType: ContactType.Driver,
        contact: driver,
      }
    : {
        contactType: ContactType.Other,
        contact: other,
      }
}
