import { addMonths } from 'date-fns'

import { UniversalServiceCode } from '@ngb-frontend/shared/types'

import type { Contract } from '@ngb-frontend/shared/types'

export const contractEndDate = addMonths(new Date(), 1).toISOString()

export const mockContract: Contract = {
  version: 1,
  individual_lease_agreement_number: 'LP00000AA',
  status: 'Active',
  is_current_version: true,
  start_date: '2022-02-22T22:22:22.000Z',
  contracted_end_date: contractEndDate,
  customer_communicated_contracted_end_date: contractEndDate,
  expected_delivery_date: '2023-01-01T00:00:00.000Z',
  lp_entity_code: 'LPNL',
  cost_center: '13467',
  creation_date: '2022-04-01T10:22:15.072Z',
  mileage_per_year: 5000,
  duration: 36,
  product_name: 'Foo Bar',
  drivers: [
    {
      external_contact_id: 'C-12345',
      first_name: 'AA',
      last_name: 'sf',
    },
  ],
  services: [
    UniversalServiceCode.RPVRegularMaintenance,
    UniversalServiceCode.RegularMaintenance,
    UniversalServiceCode.Repair,
    UniversalServiceCode.MOT,
    UniversalServiceCode.EOL,
  ].map((code) => ({
    service_operational_buffers: [],
    universal_service_code: code,
    universal_service_group: 'SGP-REPLV',
    service_state: {
      start_date: '2022-08-04',
      contracted_end_date: '2025-08-03',
      actual_end_date: '',
      customer_communicated_contracted_end_date: '2025-08-03',
    },
    creation_date: '2022-08-05T19:53:45.856Z',
    attributes: [
      {
        name: 'PAR-MLST-VHCCATTYPE',
        value: 'categoryC',
        parameter_code: 'PAR-MLST-VHCCATTYPE',
        hss_description: '',
      },
    ],
    service_objects: [],
    service_pricing: {
      service_vat_code: 'VAT STANDARD',
      service_payment: {
        periodic_payment_vat_code: 'VAT STANDARD',
      },
    },
  })),
  customer: {
    name: 'Tony Stark',
    external_account_id: 'A-12345',
  },
  other_party: {
    name: 'OVEN FOR CAKE',
    external_account_id: 'A-123456',
    type: 'intermediary',
  },
  account_manager: {
    first_name: 'John',
    last_name: 'Doe',
    external_account_id: '0051G000007csLXQAY',
  },
  lease_object: {
    // The id is hardcoded in the codebase to be able to fetch suppliers from SF
    // Keep it Fleetops returns us ids that generate suppliers
    lease_object_id: 'CTR000495',
    uin: '3Y1SL65848Z411439',
    registration_number: 'EK 5699',
    is_custom_registration_number: true,
    last_update_date: '2022-08-05T19:53:45.856Z',
  },
}
