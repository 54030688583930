export enum ErrorVariants {
  Generic = 'generic',
  Vehicle = 'vehicle',
  Contract = 'contract',
  ContractMalformed = 'contractMalformed',
  Object = 'object',
  Driver = 'driver',
  Supplier = 'supplier',
  ServiceHistory = 'serviceHistory',
  FetchBooking = 'fetchBooking',
  UnsupportedCountry = 'unsupportedCountry',
  Query = 'query',
  Country = 'country',
  Service = 'service',
  ContractInactive = 'contractInactive',
  SystemCode = 'systemCode',
}
