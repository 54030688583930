import { lazy, Suspense } from 'react'

import {
  type StepComponentContainer,
  type SummaryStepPrerequisiteData,
  StepVariants,
} from '@ngb-frontend/shared/types'
import { FullScreenLoader } from '@ngb-frontend/shared/ui'

import { hasContactFormValues } from '../ContactStep/ContactStep'

const SummaryStepLazy = lazy(() => import('./SummaryStep'))

export const SummaryStepContainer: StepComponentContainer<
  {},
  SummaryStepPrerequisiteData
> = (props) => {
  return (
    <Suspense fallback={<FullScreenLoader />}>
      <SummaryStepLazy {...props} />
    </Suspense>
  )
}

SummaryStepContainer.areStepPrerequisitesMet = (
  mergedFlowData,
): mergedFlowData is SummaryStepPrerequisiteData =>
  hasContactFormValues(mergedFlowData)

SummaryStepContainer.getVariant = () =>
  window.location.search ? StepVariants.Overview : StepVariants.Summary
