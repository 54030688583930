export const string = (
  value?: string | null | (string | null | undefined)[],
) => {
  if (!value) return ''

  return typeof value === 'string'
    ? `${value}`.trim()
    : value.reduce<string>((acc, v) => {
        if (!v) return acc
        return `${acc} ${v}`.trim()
      }, '')
}
