import { format } from 'date-fns'
import { getDefaultOptions } from 'date-fns/getDefaultOptions'
import { formatInTimeZone as formatTZ, fromZonedTime } from 'date-fns-tz'

import { defaultDateFnsLocale, getDateFnsLocale } from './dateFnsLocales'
import { countryL10n } from './l10n'

import type { CountryCode } from '@ngb-frontend/shared/types'
import type { Locale } from 'date-fns/types'

type DateTimeFormatWidth = 'short' | 'medium' | 'long' | 'full'
type DateTimeFormatOpts = {
  width?: DateTimeFormatWidth
  formatTime?: boolean
  manualLocale?: Locale
}

type TimeZoneFormatOpts = {
  country: CountryCode // when we define locales, we should use them as primary keys
}

export const getDateTimeFormat = (
  opts: DateTimeFormatOpts = { width: 'short', formatTime: false },
) => {
  const nxtDateFnsLocale =
    opts.manualLocale || getDefaultOptions().locale || defaultDateFnsLocale

  return nxtDateFnsLocale.formatLong?.[opts.formatTime ? 'time' : 'date']({
    width: opts.width,
  })
}

export const formatDateTime = (
  dateTime = '',
  opts: DateTimeFormatOpts = { width: 'medium', formatTime: false },
) => {
  const nxtDateFnsLocale =
    opts.manualLocale || getDefaultOptions().locale || defaultDateFnsLocale

  try {
    return dateTime
      ? format(
          new Date(dateTime),
          getDateTimeFormat({ ...opts, manualLocale: nxtDateFnsLocale }),
          { locale: nxtDateFnsLocale },
        )
      : '-'
  } catch {
    return dateTime
  }
}
export const formatInTimeZone = (
  utcDateTime = '',
  opts: TimeZoneFormatOpts,
) => {
  const { localeId, tz } = countryL10n[opts.country] || {}
  try {
    return formatTZ(new Date(utcDateTime), tz, "yyyy-MM-dd'T'HH:mm:ss.000'Z'", {
      locale: getDateFnsLocale(localeId),
    })
  } catch {
    return utcDateTime
  }
}

export const formatZonedToUTC = (
  zonedDateTime: Date,
  opts: TimeZoneFormatOpts,
) => {
  const { tz } = countryL10n[opts.country] || {}
  try {
    return fromZonedTime(new Date(zonedDateTime), tz)
  } catch {
    return zonedDateTime
  }
}
