import { Accordion, Grid, RadiobuttonCardGroup } from '@velocity/ui'
import React, { useMemo } from 'react'

import { useContent } from '@ngb-frontend/content'

import { InspectionDetails } from './InspectionDetails'
import { useInspectionsFieldStyles } from './InspectionsField.styled'
import { FormField } from '../FormField/FormField'
import { FormFieldGroupWrapper } from '../FormFIeldGroupWrapper/FormFieldGroupWrapper'

import type {
  InspectionGroup,
  InspectionSets,
  InspectionCode,
  FormFieldWrapper,
} from '@ngb-frontend/shared/types'
import type { ReactElement } from 'react'

interface InspectionsFieldProps
  extends Pick<FormFieldWrapper, 'label' | 'error' | 'dirty'> {
  inspections: InspectionSets
  initialValues?: InspectionCode
  row?: boolean
}

export const InspectionsField: React.FC<InspectionsFieldProps> = ({
  inspections,
  initialValues,
  row,
  label,
  error,
  dirty,
}) => {
  const classes = useInspectionsFieldStyles({ row })
  const c = useContent()

  const fields = useMemo(
    () =>
      Object.keys(inspections).map((group, idx) => (
        <Grid.Item LG={4} key={idx}>
          <Accordion
            initialExpanded={
              initialValues &&
              inspections?.[group as InspectionGroup]?.includes(initialValues)
            }
            className={classes.accordion}
            data-e2e-component="inspection-accordion"
          >
            <Accordion.Header>
              {c(`steps.inspection.group.${group}`)}
            </Accordion.Header>
            <Accordion.Content>
              <FormField<string>
                id={`inspection-${group}`}
                name="inspection"
                label={c(`steps.inspection.group.${group}`)}
                hideLabel
                group={true}
                required={false}
                hideOptionalLabel={true}
              >
                {({ input }) => {
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  const { onFocus, onBlur, onChange, ...restInput } = input

                  return (
                    <RadiobuttonCardGroup
                      {...restInput}
                      className={classes.checkboxCardGroup}
                      onChange={(e) => onChange(e.target.value)}
                      options={inspections?.[group as InspectionGroup]?.map(
                        (props) => {
                          const { code, translation } =
                            typeof props === 'string'
                              ? { code: props, translation: props }
                              : props
                          return {
                            title: c(
                              `steps.inspection.item.${translation}.title`,
                              {
                                fallback: code,
                              },
                            ),
                            details: (
                              <InspectionDetails
                                code={code}
                                when={c(
                                  `steps.inspection.item.${translation}.when`,
                                )}
                                vehicleClass={c(
                                  `steps.inspection.item.${translation}.vehicleClass`,
                                  {
                                    separator: '|',
                                  },
                                ).split('|')}
                              />
                            ),
                            value: code,
                          }
                        },
                      )}
                      data-e2e-component={`inspections-${group}-group`}
                    />
                  )
                }}
              </FormField>
            </Accordion.Content>
          </Accordion>
        </Grid.Item>
      )) as unknown as ReactElement,
    [
      c,
      classes.accordion,
      classes.checkboxCardGroup,
      initialValues,
      inspections,
    ],
  )

  return (
    <FormFieldGroupWrapper label={label} error={error} dirty={dirty}>
      <Grid
        className={classes.root}
        data-e2e-component="inspections"
        gutter={{
          x: 'var(--vel-layout-spacing-06)',
          y: 'var(--vel-layout-spacing-02)',
        }}
      >
        {fields}
      </Grid>
    </FormFieldGroupWrapper>
  )
}
