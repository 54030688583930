import type { Place } from '@velocity/ui'
import type { MutableState, Tools } from 'final-form'

export const setAddressFromPlace = <TFormValues>(
  [address, key = 'address']: [Place & { county?: string }, string],
  state: MutableState<TFormValues>,
  { changeValue }: Tools<TFormValues>,
) => {
  changeValue(state, key, () => ({
    city: address.city,
    house_number: address.streetNumber,
    street: address.streetName,
    postcode: address.postalCode,
    ...(address.county ? { county: address.county } : {}),
  }))
}
