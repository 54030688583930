import type {
  ServiceOptionValue,
  TimeFieldProps,
} from '@ngb-frontend/shared/types'

type ServiceTimeRecord = {
  type: ServiceOptionValue
  time: Pick<TimeFieldProps, 'minTime' | 'maxTime'>
}

export const defaultOperationTime = {
  minTime: '08:00',
  maxTime: '17:00',
}

const orderedServiceTimes: ServiceTimeRecord[] = [
  {
    type: 'CollectAndReturn',
    time: {
      minTime: '08:30',
      maxTime: '10:00',
    },
  },
  {
    type: 'RegularMaintenance',
    time: {
      minTime: '08:00',
      maxTime: '13:00',
    },
  },
  {
    type: 'Repair',
    time: {
      minTime: '08:00',
      maxTime: '13:00',
    },
  },
  {
    type: 'MOT',
    time: defaultOperationTime,
  },
]

/**
 * Returns the business defines min-max hours based on service type.
 * @param srv - an array of services and extra service values
 */
export const getServiceOperatingHours = (services: ServiceOptionValue[]) => {
  if (!services.length) return defaultOperationTime

  return (
    orderedServiceTimes.find((orderedSrv) => {
      return services.includes(orderedSrv.type)
    })?.time || defaultOperationTime
  )
}
