import { lazy, Suspense } from 'react'

import { FullScreenLoader } from '@ngb-frontend/shared/ui'

import { hasLocationValues } from '../LocationStep/LocationStep'
import { hasOtherLocationValues } from '../OtherLocationStep/OtherLocationStep'

import type {
  ContactStepPrerequisiteData,
  ContactStepValues,
  StepComponentContainer,
} from '@ngb-frontend/shared/types'

const ContactStepLazy = lazy(() => import('./ContactStep'))

export const ContactStepContainer: StepComponentContainer<
  ContactStepValues,
  ContactStepPrerequisiteData
> = (props) => {
  return (
    <Suspense fallback={<FullScreenLoader />}>
      <ContactStepLazy {...props} />
    </Suspense>
  )
}

ContactStepContainer.areStepPrerequisitesMet = (
  mergedFlowData,
): mergedFlowData is ContactStepPrerequisiteData => {
  return (
    hasLocationValues(mergedFlowData) || hasOtherLocationValues(mergedFlowData)
  )
}
