import { useRouter } from 'next/router'
import React, { useEffect } from 'react'

import { ErrorVariants, UserType } from '@ngb-frontend/shared/types'
import {
  NotificationViewError,
  StepLayout as Layout,
} from '@ngb-frontend/shared/ui'
import { useQuery } from '@ngb-frontend/shared/utils'

import type { GetServerSideProps, NextPage } from 'next'

export type PageProps = { type: ErrorVariants }

const ErrorPage: NextPage<PageProps> = ({ type }) => {
  const router = useRouter()
  const query = useQuery()
  const onClose = React.useCallback(
    () => router.push(query?.closeUrl || ''),
    [query?.closeUrl, router],
  )

  useEffect(() => {
    if (!window.location.href.includes(type)) {
      // for cases where the component is rendered without routing
      router.push(`/error/${type}`, undefined, { shallow: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Layout navLabel="" showLogo={true}>
      <NotificationViewError
        variant={
          Object.values(ErrorVariants).includes(type)
            ? type
            : ErrorVariants.Generic
        }
        type={query?.userType || UserType.Unknown}
        onSubmit={onClose}
      />
    </Layout>
  )
}

export const getServerSideProps: GetServerSideProps<{}, PageProps> = async ({
  query,
}) => ({ props: { type: query?.errorType } })

export default ErrorPage
