import { lazy, Suspense } from 'react'

import {
  StepVariants,
  type ServiceLocationStepPrerequisiteData,
  type ServiceLocationStepValues,
  type StepComponentContainer,
} from '@ngb-frontend/shared/types'
import {
  hasEndOfLeaseFormValues,
  hasMaintenanceAndRepairFormValues,
} from '@ngb-frontend/shared/utils'

import { FullScreenLoader } from '../../FullScreenLoader/FullScreenLoader'

const ServiceLocationStepLazy = lazy(() => import('./ServiceLocationStep'))

export const ServiceLocationStepContainer: StepComponentContainer<
  ServiceLocationStepValues,
  ServiceLocationStepPrerequisiteData
> = (props) => {
  return (
    <Suspense fallback={<FullScreenLoader />}>
      <ServiceLocationStepLazy {...props} />
    </Suspense>
  )
}

ServiceLocationStepContainer.getVariant = (mergedFlowData) =>
  mergedFlowData.extraServices?.includes('CollectAndReturn') ||
  mergedFlowData.services?.includes('EOL')
    ? StepVariants.ServiceLocationWithPickup
    : StepVariants.ServiceLocation

ServiceLocationStepContainer.areStepPrerequisitesMet = (
  mergedFlowData,
): mergedFlowData is ServiceLocationStepPrerequisiteData =>
  hasMaintenanceAndRepairFormValues(mergedFlowData) ||
  hasEndOfLeaseFormValues(mergedFlowData)
