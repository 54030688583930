import { Grid } from '@velocity/ui'
import React from 'react'
import { Form } from 'react-final-form'

import { services } from '@ngb-frontend/shared/mocks'
import { FormFooter, ServicesField } from '@ngb-frontend/shared/ui'
import { camelize } from '@ngb-frontend/shared/utils'

import type {
  ServiceFormProps,
  ServiceFormValues,
} from '@ngb-frontend/shared/types'

export const ServiceForm: React.FC<ServiceFormProps> = ({
  onSubmit,
  initialValues,
  variant,
  enabledServices,
}) => {
  const handleSubmit = React.useCallback(
    (values: ServiceFormValues) => {
      onSubmit?.({
        [camelize(variant) as keyof ServiceFormValues]: values.services || [],
      })
    },
    [onSubmit, variant],
  )

  return (
    <Form<ServiceFormValues>
      onSubmit={handleSubmit}
      initialValues={initialValues}
    >
      {(formProps) => (
        <form
          onSubmit={formProps.handleSubmit}
          data-e2e-component="service-form"
        >
          <Grid>
            <Grid.Item LG={6}>
              <ServicesField
                services={services[variant]}
                variant={variant}
                enabledServices={enabledServices}
                hideLabel
              />
            </Grid.Item>
          </Grid>
          <FormFooter />
        </form>
      )}
    </Form>
  )
}
