import { makeStyles } from '@material-ui/core'

import type { FullScreenLoaderProps } from './FullScreenLoader'
import type { Theme } from '@material-ui/core'

export const useFullscreenLoaderStyles = makeStyles<
  Theme,
  Pick<FullScreenLoaderProps, 'variant'>
>(() => ({
  root: ({ variant }) =>
    variant === 'fixed'
      ? {
          position: 'fixed',
          height: '100vh',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 10000,
          backgroundColor: 'rgba(var(--vel-color-neutral-900-raw), 0.4)',
        }
      : {
          minHeight: variant === 'contained' ? '100%' : '100vh',
        },
}))
