import { setDefaultOptions } from 'date-fns/setDefaultOptions'
import IntlMsgFormat from 'intl-messageformat'
import React, {
  type ReactNode,
  useMemo,
  isValidElement,
  useCallback,
  useEffect,
} from 'react'

import defaultContent from './translations/translations.json'

import type { getCountryL10nCtx } from './l10n'
import type { FormatXMLElementFn } from 'intl-messageformat'

type Content =
  | string
  | number
  | boolean
  | { [x: string]: Content }
  | Array<Content>
export interface ContentProviderProps {
  l10nCtx?: ReturnType<typeof getCountryL10nCtx>
  children?: ReactNode
}

interface ContentContextProps<T extends Content = typeof defaultContent> {
  content?: T
  locale?: Required<ContentProviderProps>['l10nCtx']['localeId']
}

export type RichTextFormatFn = FormatXMLElementFn<ReactNode>
interface ContentOptions<
  T extends RichTextFormatFn | string = string | RichTextFormatFn,
> {
  variables?: Record<string, T>
  separator?: string
  fallback?: string
}

// Formats one or many messages into a single string. Used in almost all cases.
const formatToStringText = (
  msg: string | string[],
  variables: ContentOptions<string>['variables'],
  locale: string | string[],
  separator = ',',
) => {
  return (!Array.isArray(msg) ? [msg] : msg)
    .map((part) => String(new IntlMsgFormat(part, locale).format(variables)))
    .join(separator)
}

// Formats one or many xml messages into an array of rich content (html links etc...)
const formatToRichText = (
  msg: string | string[],
  variables: ContentOptions['variables'],
  locale: string | string[],
) => {
  if (!Array.isArray(msg)) {
    const richContent = new IntlMsgFormat(msg, locale).format(variables) || ''

    return !React.isValidElement(richContent) && !Array.isArray(richContent)
      ? String(richContent)
      : richContent
  }

  return msg
    .map((part) => new IntlMsgFormat(part, locale).format(variables))
    .flat()
}

const ContentContext = React.createContext<ContentContextProps>({
  content: undefined,
  locale: undefined,
})
export const ContentProvider: React.FC<ContentProviderProps> = ({
  l10nCtx,
  children,
}) => {
  const [content, setContent] =
    React.useState<ContentContextProps['content']>(defaultContent)
  const { locale, ...l10nConfig } = l10nCtx || { localeId: undefined }
  const localeId = l10nConfig.localeId

  // Global date-fns locale configuration
  useEffect(() => {
    if (!localeId) return
    const dateFnsLocale = locale?.[localeId]
    setDefaultOptions({ locale: dateFnsLocale })
  }, [l10nConfig, l10nConfig.localeId, locale, localeId])

  React.useEffect(() => {
    const getContent = async () => {
      if (localeId === 'enGB') return
      try {
        const json = await import(
          `./translations/${localeId}-translations.json`
        )
        setContent(json)
      } catch {
        // eslint-disable-next-line no-console
        console.warn(`No translation file found for locale "${localeId}"`) // NOSONAR
      }
    }
    getContent()
  }, [locale, localeId])

  return (
    <ContentContext.Provider value={{ content, locale: localeId }}>
      {children}
    </ContentContext.Provider>
  )
}

export const useContent = () => {
  const ctx = React.useContext(ContentContext)
  const locale = ctx.locale || 'enGB'

  if (!ctx.content) {
    throw new Error('useContent must be used within a ContentProvider')
  }

  const findByPath = React.useCallback(
    (path: string, content: Content): Content | undefined => {
      // @ts-ignore
      return path.split('.').reduce((acc, curr) => acc?.[curr], content)
    },
    [],
  )

  const findContent: (path: string, fallback?: string) => Content | undefined =
    React.useCallback(
      (path: string, fallback?: string) => {
        let message = findByPath(path, ctx.content as Content)

        if (!message) {
          message = findByPath(path, defaultContent)
        }

        if (!message) {
          if (!fallback) {
            // eslint-disable-next-line no-console
            console.warn(`No content found for path "${path}"`) // NOSONAR
          } else {
            return findContent(fallback)
          }
        }

        return message
      },
      [ctx.content, findByPath],
    )

  const formatCore = useCallback(
    <T extends ContentOptions>(
      path: string,
      opts: T | undefined,
      cb: (
        path: string | string[],
        vars: T['variables'],
        locale: string,
        separator: T['separator'],
      ) => string | ReactNode | ReactNode[],
    ) => {
      const { variables, fallback, separator } = opts || {}

      const message = findContent(path, fallback)
      // TODO: Revise strategy of fetching structures different to string | string[]
      if (!(Array.isArray(message) || typeof message === 'string')) {
        return path
      }

      const msgAsOneOrChunkedStrings = Array.isArray(message)
        ? message.filter((part): part is string => typeof part === 'string')
        : message

      const unformattedMessage = Array.isArray(msgAsOneOrChunkedStrings)
        ? msgAsOneOrChunkedStrings.join(separator)
        : msgAsOneOrChunkedStrings

      if (!variables) return unformattedMessage

      try {
        return cb(msgAsOneOrChunkedStrings, variables, locale, separator)
      } catch (e) {
        return unformattedMessage
      }
    },
    [findContent, locale],
  )

  const format = useMemo(() => {
    const f = (path: string, opts?: ContentOptions<string>) => {
      return `${formatCore(path, opts, formatToStringText)}`
    }

    f.rich = (path: string, opts?: ContentOptions) => {
      const richText = formatCore(path, opts, formatToRichText)

      if (!Array.isArray(richText)) return richText

      return richText.map((t, i) =>
        isValidElement(t) ? <React.Fragment key={i}>{t}</React.Fragment> : t,
      )
    }

    return f
  }, [formatCore])

  return format
}
