import { makeStyles } from '@material-ui/core'

import type { Theme } from '@material-ui/core'

export const useRadioCardStyles = makeStyles<
  Theme,
  { checked: boolean; borderColor?: 'primary' | 'neutral' } | {}
>(() => ({
  root: {
    position: 'relative',
    userSelect: 'none',
    marginBottom: 'var(--vel-fixed-spacing-05)',
    cursor: 'pointer',
    border: (props) =>
      `1px solid ${
        // @ts-ignore
        props?.checked
          ? `var(--vel-color-${
              // @ts-ignore
              props?.borderColor ? props?.borderColor : 'primary'
            }-400)`
          : 'transparent'
      }`,
    borderRadius: 'var(--vel-fixed-spacing-02)',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    wordBreak: 'break-word',
  },
  textContainer: {
    flexGrow: 1,
    paddingTop: 'var(--vel-fixed-spacing-03)',
  },
  buttonContainer: {
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    marginLeft: 'var(--vel-fixed-spacing-07)',
  },
  radioContainer: {
    paddingRight: 'var(--vel-fixed-spacing-07)',
  },
  collapsedContent: {
    marginTop: 'var(--vel-fixed-spacing-05)',
    paddingLeft: 'var(--vel-fixed-spacing-09)',
  },
  tooltip: {
    position: 'absolute',
    top: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'row',
    '& > div:last-child': {
      padding:
        'var(--vel-fixed-spacing-02) var(--vel-fixed-spacing-03) var(--vel-fixed-spacing-02) var(--vel-fixed-spacing-05)',
      borderRadius: 'var(--vel-fixed-spacing-02)',
      borderBottomRightRadius: 0,
    },
  },
}))
