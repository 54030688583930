import { PublicClientApplication } from '@azure/msal-browser'

import type { MSALClientConfig } from '@ngb-frontend/shared/types'

export const createMSALClient = (config: MSALClientConfig) => {
  const pca = new PublicClientApplication({
    auth: { ...config, redirectUri: '/' },
    cache: { cacheLocation: 'localStorage' },
  })

  return pca
}
