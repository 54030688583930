import type {
  MalfunctionFormValues,
  MalfunctionSets,
} from '@ngb-frontend/shared/types'

export const malfunctionSets = {
  lighting: ['HeadLightNotWorking', 'LightNotWorking'],
} as unknown as MalfunctionSets

export const malfunctionFormData = Object.entries(malfunctionSets).reduce(
  (acc, [set, setItems]) => {
    if (!setItems.length) return acc

    return {
      ...acc,
      [set]: setItems,
    }
  },
  {},
) as MalfunctionFormValues['malfunctions']
