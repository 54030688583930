/**
 * NB! This function sets the datetime to local system time based on the browser.
 * Make sure to convert it to UTC when required.
 */
export const setDateTime = (d: Date | string, time: string) => {
  const cloneDate = new Date(d)
  const [h, m] = time.split(':')
  cloneDate.setHours(+h, +m, 0, 0)

  return cloneDate
}
