import { lazy, Suspense } from 'react'

import { MaintenanceAndRepair } from '@ngb-frontend/shared/types'
import { FullScreenLoader } from '@ngb-frontend/shared/ui'
import { camelize } from '@ngb-frontend/shared/utils'

import { hasServiceFormValues } from '../ServiceStep/ServiceStep'

import type {
  ReplacementVehicleStepPrerequisiteData,
  ReplacementVehicleStepValues,
  StepComponentContainer,
} from '@ngb-frontend/shared/types'

const ReplacementVehicleStepLazy = lazy(
  () => import('./ReplacementVehicleStep'),
)

export const ReplacementVehicleStepContainer: StepComponentContainer<
  ReplacementVehicleStepValues,
  ReplacementVehicleStepPrerequisiteData
> = (props) => {
  return (
    <Suspense fallback={<FullScreenLoader />}>
      <ReplacementVehicleStepLazy {...props} />
    </Suspense>
  )
}

ReplacementVehicleStepContainer.areStepPrerequisitesMet = (
  mergedFlowData,
): mergedFlowData is ReplacementVehicleStepPrerequisiteData =>
  hasServiceFormValues(
    mergedFlowData,
    camelize(MaintenanceAndRepair.ExtraService),
  )

ReplacementVehicleStepContainer.skip = (mergedFlowData) => {
  return {
    enabled: !mergedFlowData.extraServices?.includes('ReplacementVehicle'),
    state: {
      ...(Array.isArray(mergedFlowData.services)
        ? {
            replacementVehicle: false,
            courtesyVehicle: false,
            replacementVehicleCategory: undefined,
          }
        : /**
           * Ensure that no valid state is written when the state has been just reset
           * If this would not be present, going to this step after reseting cache (with url)
           * would write a valid step state and the next step's prerequisites would be
           * considered fulfilled. This is an open-bug case for any skipable step;
           * TODO: go-through all of skippable steps and ensure that a valid default
           * state is written only when the state of previous steps is also present
           */
          {
            replacementVehicle: undefined,
            courtesyVehicle: undefined,
            replacementVehicleCategory: undefined,
          }),
    },
  }
}
