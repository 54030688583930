import { string } from '@ngb-frontend/shared/utils'

import { CardButton } from '../CardButton/CardButton'

import type { CardButtonProps } from '../CardButton/CardButton'
import type { Address } from '@ngb-frontend/shared/types'
import type { ReactNode } from 'react'

export interface AddressCardButtonProps
  extends Omit<CardButtonProps, 'details' | 'children'> {
  address?: Omit<Address, 'country_code'>
  children?: ReactNode
}

export const AddressCardButton: React.FC<AddressCardButtonProps> = ({
  address,
  children,
  ...rest
}) => (
  <CardButton {...rest} disabled={!address}>
    {string([
      address?.street,
      address?.house_number,
      address?.house_number_suffix,
    ])}
    <br />
    {string([address?.postcode, address?.city])}
  </CardButton>
)
