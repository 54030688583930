import * as R from 'ramda'

import { hasAddress } from '../typeguards/hasAddress'

import type { EndOfLeaseFormValues } from '@ngb-frontend/shared/types'

export const hasEndOfLeaseFormValues = (
  x: unknown,
): x is EndOfLeaseFormValues =>
  R.allPass([
    R.pipe(R.prop('mileage'), R.is(Number)),
    R.pipe(R.prop('contactType'), R.is(String)),
    R.pipe(
      R.prop('contact'),
      R.allPass([
        R.pipe(R.prop('firstName'), R.is(String)),
        R.pipe(R.prop('lastName'), R.is(String)),
        R.pipe(R.prop('email'), R.is(String)),
        R.pipe(R.prop('phone'), R.is(String)),
      ]),
    ),
    R.pipe(R.prop('driverDeceased'), R.is(Boolean)),
    R.pipe(R.prop('vehicleDrivable'), R.is(Boolean)),
    R.pipe(R.prop('dateTime'), R.is(String)),
    R.pipe(R.prop('address'), hasAddress),
    R.pipe(R.prop('timeSlot'), R.is(String)),
  ])(x)
