import {
  BookingStatus,
  RepossessionType,
  TimeSlot,
} from '@ngb-frontend/shared/types'

import { mockDriver } from './driver'
import { malfunctionSets } from './malfunction'
import { mockOtherContact } from './otherContact'
import { mockService, mockServiceItem } from './service'
import { mockSupplier } from './supplier'

import type {
  Booking,
  CreateBookingRequest,
  CreateBookingResponse,
} from '@ngb-frontend/shared/types'

export const bookingDriverEmail = 'bookingDriver.email.com'

export const mockCreateBookingReq: CreateBookingRequest = {
  request_type: 'RMT Booking',
  global_reference_id: 'SFNGLPUKa4R7Y000000TUYUUA4',
  case_id: '5007Y000000TUYUUA4',
  agent_id: 'agent_id',
  booking_number: 'BR-123456',
  contact_details: mockOtherContact,
  case_url: 'https://login.salesforce.com/5007Y000000TUYUUA4',
  country_code: 'LPUK',
  network_id: 'ASDGD46F',
  service_date_time: '2022-02-10T10:00:00.000Z',
  preferred_pickup_time: '2022-02-10T10:00:00.000Z',
  mileage: 12300,
  status: BookingStatus.Unconfirmed,
  status_reason: 'Changing the garage',
  mot_type: 'LegalInspectionClass2',
  driver: mockDriver,
  vehicle: {
    id: '24938384483838',
    make: 'BMW',
    type: 'BMW',
    transmission: 'Automatic',
  },
  service: mockService,
  additional_services: [mockServiceItem],
  supplier: mockSupplier,
  malfunctions: malfunctionSets.lighting,
  malfunction_description: '',
  courtesy_vehicle: true,
  replacement_mobility: 'RentalByExternalCompany',
  replacement_vehicle_data: null,
  vehicle_return_data: {
    drivable: 'No',
    customer_deceased: false,
    collection_time: TimeSlot.AM,
    county: 'Somewhere',
    nondrivable_reason: 'No wheels',
    repossession: true,
    repossession_type: RepossessionType.Friendly,
  },
  service_slot: TimeSlot.AM,
}

export const mockCreateBookingRes: CreateBookingResponse = {
  id: 'SF-003030202033920',
  global_reference_id: 'SFNGLPUKa4R7Y000000TUYUUA4',
}

export const mockBooking: Booking = {
  ...mockCreateBookingRes,
  ...mockCreateBookingReq,
}

export const mockFetchBooking: Booking[] = [mockBooking]
