import { AyvensFavicon } from '@velocity/icons/favicon/ayvens-favicon-react'
import { LeaseplanFavicon } from '@velocity/icons/favicon/leaseplan-favicon-react'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import { useMemo } from 'react'

import { AppTheme } from '@ngb-frontend/shared/types'
import { useQuery } from '@ngb-frontend/shared/utils'

import type { VelocityProviderProps } from '@velocity/ui'

const LeaseplanThemeProvider = dynamic(
  () => import('./providers/LeaseplanThemeProvider'),
)

const AyvensThemeProvider = dynamic(
  () => import('./providers/AyvensThemeProvider'),
)

const themeProviderByAppTheme = (theme?: AppTheme) => {
  switch (theme) {
    case AppTheme.Ayvens:
      return AyvensThemeProvider
    case AppTheme.Leaseplan:
    default:
      return LeaseplanThemeProvider
  }
}

const faviconByAppTheme = (theme?: AppTheme) => {
  switch (theme) {
    case AppTheme.Ayvens:
      return AyvensFavicon
    case AppTheme.Leaseplan:
    default:
      return LeaseplanFavicon
  }
}

export const ThemeProvider = ({
  children,
  ...props
}: React.PropsWithChildren<Omit<VelocityProviderProps, 'theme'>>) => {
  const theme = useQuery()?.theme

  const ThemeProvider = useMemo(() => themeProviderByAppTheme(theme), [theme])
  const Favicon = useMemo(() => faviconByAppTheme(theme), [theme])

  return (
    <>
      <Head key="theme">
        <Favicon />
      </Head>
      <ThemeProvider {...props}>{children}</ThemeProvider>
    </>
  )
}
