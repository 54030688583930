import { ChevronRightIcon } from '@velocity/icons/system'
import { Badge, Card, Headline, Text } from '@velocity/ui'

import { useCardButtonStyles } from './CardButton.styled'

import type { SystemIcon, SystemIconProps } from '@velocity/icons/system'
import type { BadgeProps, CardProps } from '@velocity/ui'
import type { ReactNode } from 'react'

export interface CardButtonProps {
  title: string
  onClick: CardProps['onClick']
  icon?: SystemIcon
  iconColor?: SystemIconProps['color']
  disabled?: boolean
  label?: string
  badge?: Pick<BadgeProps, 'Icon' | 'kind'> & {
    text: string
  }
  children: ReactNode
}

export const CardButton: React.FC<CardButtonProps> = ({
  title,
  children,
  onClick,
  icon: Icon = ChevronRightIcon,
  iconColor = 'primary',
  disabled,
  label,
  badge,
  ...rest
}) => {
  const classes = useCardButtonStyles({ disabled })

  return (
    <div className={classes.root} {...rest}>
      <Card
        onClick={!disabled ? onClick : undefined}
        data-e2e-component="card-button"
      >
        <Card.Body className={classes.content}>
          {label && <div className={classes.label}>{label}</div>}
          <div>
            {badge && (
              <Badge
                className={classes.badge}
                kind={disabled ? 'light' : badge.kind}
                Icon={badge.Icon}
              >
                {badge.text}
              </Badge>
            )}
            <Headline variant="100" withMarginBottom className={classes.title}>
              {title}
            </Headline>
            <Text variant="100">{children}</Text>
          </div>
          <div className={classes.buttonContainer}>
            <Icon size="m" color={iconColor} />
          </div>
        </Card.Body>
      </Card>
    </div>
  )
}
