import { Input } from '@velocity/ui'

import { useContent } from '@ngb-frontend/content'
import { validation as v } from '@ngb-frontend/shared/utils'

import { FormField } from '../FormField/FormField'

import type { FormFieldWrapper } from '@ngb-frontend/shared/types'

export const MileageField: React.FC<FormFieldWrapper> = ({
  classes,
  label,
  hideLabel,
  name = 'mileage',
  noMargin,
}) => {
  const c = useContent()
  return (
    <FormField<string>
      name={name}
      label={label || c('fields.mileage.label')}
      hideLabel={hideLabel}
      id="mileage-input"
      validator={v.apply(
        v.minMaxValue(
          v.validationDefaults.minMileageValue,
          v.validationDefaults.maxMileageValue,
        ),
        {
          message: c('errors.mileage'),
          type: 'errorType',
        },
      )}
      classes={classes}
      noMargin={noMargin}
    >
      {({ input }) => (
        <Input
          inputProps={{
            'aria-label': 'mileage',
            'data-e2e-component': 'mileage-input',
          }}
          {...input}
          type="number"
          required={false}
          endAdornment={c('fields.mileage.mileageUnitAbbreviation')}
        />
      )}
    </FormField>
  )
}
