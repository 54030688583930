import { type Theme, makeStyles } from '@material-ui/core'

interface Props {
  inline?: boolean
}

export const useDateTimeFieldStyles = makeStyles<Theme, Props>(() => ({
  container: {
    flexDirection: ({ inline }) => (inline ? 'row' : 'column'),
  },
  date: {
    flexGrow: 1,
  },
  time: ({ inline }) => ({
    '&&': {
      maxWidth: inline ? 130 : 'auto',
      marginLeft: inline ? 'var(--vel-fixed-spacing-04)' : 0,
      '& #time_label': {
        margin: 0,
      },
    },
  }),
}))
