import type { operations } from '../../__generated__/sims-vehicle-service'

export enum LegacyVehicleServiceCode {
  ServiceOnly = 'ServiceOnly',
  FullService = 'FullService',
  RechargeService = 'RechargeService',
}

type BaseVehicleDetails =
  operations['queryView_vehicle-details']['responses']['200']['content']['application/json;charset=UTF-8;subtype=denodo-8.0']

type BaseVehicleDetailsItem = NonNullable<BaseVehicleDetails['elements']>[0]
type BaseVehicleDetailsItemService = NonNullable<
  BaseVehicleDetailsItem['vehicle_services']
>[0]

type VehicleDetailsItemService = Omit<
  BaseVehicleDetailsItemService,
  'service_code'
> & {
  service_code?: LegacyVehicleServiceCode
}

export type VehicleDetailsItem = Omit<
  BaseVehicleDetailsItem,
  'vehicle_services'
> & { vehicle_services?: VehicleDetailsItemService[] }

export type VehicleDetails = Omit<
  BaseVehicleDetails,
  'elements' | 'replacement_vehicle_yn'
> & {
  elements?: VehicleDetailsItem[]
  replacement_vehicle_yn?: 'Y' | 'N'
}
