import { makeStyles } from '@material-ui/core'
import { mq } from '@velocity/styling'

const vuiVars = {
  marginBottom: 'var(--vel-fixed-spacing-05)',
}

export const useNotificationViewStyles = makeStyles(() => ({
  root: {
    '&&': {
      [mq.max('MD')]: {
        '&&': {
          background: 'none',
          boxShadow: 'none',
        },
      },
    },
  },
  cardInner: {
    [mq.min('LG')]: {
      flexDirection: 'column',
      display: 'flex',
      padding: 'var(--vel-layout-spacing-06)',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
    },
  },
  pictogram: {
    '&&': {
      [mq.min('LG')]: {
        marginBottom: vuiVars.marginBottom,
      },
    },
  },
  spacing: {
    '&&': {
      marginBottom: vuiVars.marginBottom,
      [mq.min('LG')]: {
        marginBottom: 'var(--vel-fixed-spacing-09)',
      },
    },
  },
  content: {
    [mq.min('LG')]: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  grid: {
    width: '100%',
    justifyContent: 'center',
  },
  button: {
    '&&': {
      marginBottom: vuiVars.marginBottom,
      '&:not(a)': {
        width: '100%',
      },
      [mq.min('LG')]: {
        '&:not(a)': {
          marginBottom: 'var(--vel-fixed-spacing-09)',
        },
        padding: 'var(--vel-fixed-spacing-04) var(--vel-fixed-spacing-07)',
        fontSize: '1rem',
      },
    },
  },
}))
