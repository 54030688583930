export enum Organization {
  LP = 'LeasePlan',
  ALD = 'ALD',
}

export enum SystemCode {
  MYLP = 'MYLP', // representing myLeaseplan driver
  SFLP = 'SFLP', // representing SalesForce Leaseplan agent
  SPLP = 'SPLP', // representing SalesPortal Leaseplan broker
  MYAY = 'MYAY', // representing myAyvens driver
  SFALD = 'SFALD', // representing SalesForce ALD agent
}

export type SystemOrganization = {
  [SystemCode.MYLP]: Organization.LP
  [SystemCode.SFLP]: Organization.LP
  [SystemCode.SPLP]: Organization.LP
  [SystemCode.MYAY]: Organization.ALD
  [SystemCode.SFALD]: Organization.ALD
}
