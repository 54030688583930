import { Referer, SystemCode } from '@ngb-frontend/shared/types'

export const systemCodeToReferrer = (systemCode?: SystemCode): Referer => {
  switch (systemCode) {
    case SystemCode.SFALD:
      return Referer.SalesForce
    case SystemCode.SFLP:
      return Referer.SalesForce
    case SystemCode.SPLP:
      return Referer.SalesPortal
    case SystemCode.MYAY:
      return Referer.MyAY
    case SystemCode.MYLP:
    default:
      return Referer.MyLP
  }
}
