import type { VehicleListItem, VehicleList } from '@ngb-frontend/shared/types'

export const mockVehicleListItem: VehicleListItem = {
  object_id: '1298499',
  registration: 'YS69OZB',
  vin: 'WF0YXXTTGYKM15135',
  status: '1',
  description: 'Operational, vehicle in service',
  customer_id: '33455',
  customer_name: 'FFEC7B595770C3CC398F',
  driver_name: 'E257 690C41703',
  company_id: undefined,
  entity_code: 'LPUK',
  model_code: '53356',
  model_name: 'T/CS300L1 19.75',
  manufacturer_code: '7',
  manufacturer_name: 'FORD',
  vehicle_type: '105H2 LEADER',
}

export const mockNonOperationalVehicleListItem: VehicleListItem = {
  object_id: '1199861',
  registration: 'HY68EKX',
  vin: 'TMBKG7NE1K0038504',
  status: '5',
  description: 'In Final Settlement',
  customer_id: '34575',
  customer_name: 'MINISTRY OF DEFENCE',
  driver_name: 'POOL W01',
  company_id: undefined,
  entity_code: 'LPUK',
  model_code: '3669',
  model_name: 'OCTAVIA EST  18',
  manufacturer_code: '74',
  manufacturer_name: 'SKODA',
  vehicle_type: '1.6d S',
}

export const mockVehicleListRes: VehicleList = {
  name: 'vehicle-list',
  description: '',
  elements: [mockNonOperationalVehicleListItem, mockVehicleListItem],
}
