import { rest } from 'msw'

import {
  mockFetchBooking as mockBooking,
  mockContract,
  mockDriver,
  mockVehicleObject,
  mockFetchBooking,
  mockServiceHistoryRes,
  mockLeaseChanges,
  mockVehicleDetailsRes,
  mockVehicleListRes,
  mockBookingHistory,
  msKeys,
  mockSuppliersResponse,
  mockVehicle,
} from '@ngb-frontend/shared/mocks'

import { getTestServerConfig } from '../config/getTestServerConfig'

import type {
  Booking,
  ClientConfig,
  Contract,
  Driver,
  LeaseChange,
  ServiceHistory,
  Suppliers,
  VehicleObject,
  Search,
  Vehicle,
} from '@ngb-frontend/shared/types'

const {
  sfBase,
  denodo,
  fleetopsContractManagementBase,
  fleetopsLeaseObjectBase,
} = getTestServerConfig()

export const createApiMockHandlers = (config: ClientConfig) => [
  rest.get(config.endpoints.azurePublicKeys, (_req, res, ctx) => {
    return res(ctx.json(msKeys))
  }),
  rest.post(config.apiRoutes.leaseChanges, (_req, res, ctx) => {
    return res(ctx.json<LeaseChange[]>(mockLeaseChanges))
  }),
  rest.post(config.apiRoutes.vehicle, (_req, res, ctx) => {
    return res(ctx.json<Vehicle>(mockVehicle))
  }),
  rest.post(`${config.apiRoutes.supplierSearch}`, (_req, res, ctx) => {
    return res(ctx.json<Suppliers>(mockSuppliersResponse))
  }),
  rest.post(`${config.apiRoutes.driver}`, (_req, res, ctx) => {
    return res(ctx.json<Driver>(mockDriver))
  }),
  rest.post(`${config.apiRoutes.serviceHistory}`, (_req, res, ctx) => {
    return res(ctx.json<ServiceHistory>(mockServiceHistoryRes))
  }),
  rest.post(`${config.apiRoutes.booking}`, (req, res, ctx) => {
    switch (req.url.searchParams.get('action')) {
      case 'create': {
        return res(
          ctx.json<Pick<Booking, 'id' | 'global_reference_id'>>({
            id: mockBooking[0].id,
            global_reference_id: mockBooking[0].global_reference_id,
          }),
        )
      }
      case 'fetchHistory': {
        return res(ctx.json<Booking[]>(mockBookingHistory))
      }
      case 'update': {
        return res(ctx.body(''))
      }
      default: {
        return res(ctx.json<Booking[]>(mockFetchBooking))
      }
    }
  }),
  rest.get(
    `${fleetopsContractManagementBase}/${config.endpoints.contract}/search/*`,
    (_req, res, ctx) => {
      return res(
        ctx.json<Partial<Search<Contract>>>({
          items: [mockContract as Contract],
        }),
      )
    },
  ),
  rest.get(
    `${fleetopsContractManagementBase}/${config.endpoints.contract}/*/lease-changes`,
    (_req, res, ctx) => {
      return res(ctx.json(mockLeaseChanges))
    },
  ),
  rest.get(`${sfBase}/${config.endpoints.driver}/*`, (_req, res, ctx) => {
    return res(ctx.json<Driver>(mockDriver))
  }),
  rest.post(
    `${sfBase}/${config.endpoints.supplierSearch}`,
    (_req, res, ctx) => {
      return res(ctx.json<Suppliers>(mockSuppliersResponse))
    },
  ),

  rest.get(`${sfBase}`, (req, res, ctx) => {
    // fetch booking (when id is provided)
    if (req.url.searchParams.get('id')) {
      return res(ctx.json<Booking>(mockBooking[0]))
    }

    // else ilan or registration is provided and we get a list
    return res(ctx.json<Booking[]>(mockBooking))
  }),
  // create booking
  rest.post(`${sfBase}`, (_req, res, ctx) => {
    return res(
      ctx.json<Pick<Booking, 'id' | 'global_reference_id'>>({
        id: mockBooking[0].id,
        global_reference_id: mockBooking[0].global_reference_id,
      }),
    )
  }),
  // update booking
  rest.put(`${sfBase}/${config.endpoints.booking}/*`, (_req, res, ctx) => {
    return res(ctx.text(''))
  }),
  rest.get(
    `${fleetopsLeaseObjectBase}/${config.endpoints.vehicleObject}/*`,
    (_req, res, ctx) => {
      return res(ctx.json<VehicleObject>(mockVehicleObject as VehicleObject))
    },
  ),
  rest.get(
    `${denodo.base}/${config.endpoints.serviceHistory}`,
    (_req, res, ctx) => {
      return res(ctx.json<ServiceHistory>(mockServiceHistoryRes))
    },
  ),
  rest.get(
    `${denodo.base}/${config.endpoints.vehicleList}`,
    (_req, res, ctx) => {
      return res(ctx.json(mockVehicleListRes))
    },
  ),
  rest.get(
    `${denodo.base}/${config.endpoints.vehicleDetails}`,
    (_req, res, ctx) => {
      return res(ctx.json(mockVehicleDetailsRes))
    },
  ),
  rest.all('*datadog*', (_, res, ctx) => {
    return res(ctx.json({ mocked: true }))
  }),
]
