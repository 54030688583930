import { lazy, Suspense } from 'react'

import { FullScreenLoader } from '@ngb-frontend/shared/ui'

import { hasMileageFormValues } from '../MileageStep/MileageStep'

import type {
  ExtraServiceStepPrerequisiteData,
  ServiceStepComponentProps,
  ServiceStepPrerequisiteData,
  ServiceStepValues,
  StepComponentContainer,
} from '@ngb-frontend/shared/types'

const ServiceStepLazy = lazy(() => import('./ServiceStep'))

export const ServiceStepContainer: StepComponentContainer<
  ServiceStepValues,
  ServiceStepPrerequisiteData | ExtraServiceStepPrerequisiteData,
  ServiceStepComponentProps
> = (props) => {
  return (
    <Suspense fallback={<FullScreenLoader />}>
      <ServiceStepLazy {...props} />
    </Suspense>
  )
}

ServiceStepContainer.areStepPrerequisitesMet = (
  mergedFlowData,
): mergedFlowData is ServiceStepPrerequisiteData =>
  hasMileageFormValues(mergedFlowData)
