import {
  UniversalServiceCode,
  type ServiceValue,
} from '@ngb-frontend/shared/types'

const serviceToRPVUniversalServiceCode: Partial<{
  [key in ServiceValue]: UniversalServiceCode
}> = {
  RegularMaintenance: UniversalServiceCode.RPVRegularMaintenance,
  Repair: UniversalServiceCode.RPVRepair,
  MOT: UniversalServiceCode.RPVMOT,
}

export const mapServiceToRPVUniversalServiceCode = (
  serviceValue: ServiceValue,
) => serviceToRPVUniversalServiceCode[serviceValue]
