import { makeStyles } from '@material-ui/core'

import type { Theme } from '@material-ui/core'

interface Props {
  addressVisible?: boolean
  hideFieldsStyle?: 'visibility' | 'display'
}

export const useAddressFieldsStyles = makeStyles<Theme, Props>(() => ({
  trigger: {
    color: 'var(--vel-color-neutral-600)',
    textAlign: 'right',
    textDecoration: 'underline',
    cursor: 'pointer',
    userSelect: 'none',
  },
  error: {
    color: 'var(--vel-color-semantic-error-high)',
  },
  container: ({ addressVisible, hideFieldsStyle = 'visibility' }) => {
    return hideFieldsStyle === 'display'
      ? { display: addressVisible ? 'block' : 'none' }
      : { visibility: addressVisible ? 'visible' : 'hidden' }
  },
}))
