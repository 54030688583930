import { lazy, Suspense } from 'react'

import { MaintenanceAndRepair } from '@ngb-frontend/shared/types'
import { FullScreenLoader } from '@ngb-frontend/shared/ui'
import { camelize } from '@ngb-frontend/shared/utils'

import { hasServiceFormValues } from '../ServiceStep/ServiceStep'

import type {
  MalfunctionStepPrerequisiteData,
  MalfunctionStepValues,
  StepComponentContainer,
} from '@ngb-frontend/shared/types'

const MalfunctionStepLazy = lazy(() => import('./MalfunctionStep'))

export const MalfunctionStepContainer: StepComponentContainer<
  MalfunctionStepValues,
  MalfunctionStepPrerequisiteData
> = (props) => {
  return (
    <Suspense fallback={<FullScreenLoader />}>
      <MalfunctionStepLazy {...props} />
    </Suspense>
  )
}

MalfunctionStepContainer.areStepPrerequisitesMet = (
  mergedFlowData,
): mergedFlowData is MalfunctionStepPrerequisiteData =>
  hasServiceFormValues(mergedFlowData, camelize(MaintenanceAndRepair.Service))

MalfunctionStepContainer.skip = (mergedFlowData) => ({
  enabled: !mergedFlowData.services?.includes('Repair'),
  state: { malfunctions: {}, malfunctionDescription: '' },
})
