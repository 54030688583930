import { lazy, Suspense } from 'react'

import { StepVariants } from '@ngb-frontend/shared/types'
import { FullScreenLoader } from '@ngb-frontend/shared/ui'

import { hasDateFormValues } from '../DateStep/DateStep'

import type {
  LocationStepPrerequisiteData,
  LocationStepValues,
  StepComponentContainer,
} from '@ngb-frontend/shared/types'

const LocationStepLazy = lazy(() => import('./LocationStep'))

export const LocationStepContainer: StepComponentContainer<
  LocationStepValues,
  LocationStepPrerequisiteData
> = (props) => {
  return (
    <Suspense fallback={<FullScreenLoader />}>
      <LocationStepLazy {...props} />
    </Suspense>
  )
}

LocationStepContainer.areStepPrerequisitesMet = (
  mergedFlowData,
): mergedFlowData is LocationStepPrerequisiteData =>
  hasDateFormValues(mergedFlowData)

LocationStepContainer.getVariant = (mergedFlowData) =>
  mergedFlowData.extraServices?.includes('CollectAndReturn')
    ? StepVariants.LocationWithPickup
    : StepVariants.Location
