import { string } from '../../string/string'

import type {
  Booking,
  ContactFormValues,
  OtherContactDetails,
} from '@ngb-frontend/shared/types'

export type SFContactFields = keyof Pick<
  Booking['driver'],
  'first_name' | 'last_name' | 'email' | 'mobile_phone'
>

export const getSFDriverContact = (
  object: Partial<ContactFormValues>,
): Record<SFContactFields, string> => ({
  first_name: object.firstName || '',
  last_name: object.lastName || '',
  email: object.email || '',
  mobile_phone: object.phone || '',
})

export const getSFOtherContact = (object: Partial<ContactFormValues>) => ({
  contact_name: string([object.firstName, object.lastName]),
  contact_email: object.email || '',
  contact_phone: object.phone || '',
})

export const parseDriverContact = (object: {
  [key in SFContactFields]?: string | undefined
}): ContactFormValues => {
  return {
    firstName: string(object?.first_name),
    lastName: string(object?.last_name),
    email: string(object?.email),
    phone: string(object?.mobile_phone),
  }
}

export const parseOtherContact = (object: {
  [key in keyof OtherContactDetails]?: string | undefined
}): ContactFormValues => {
  const name = object.contact_name?.split(' ')
  return {
    firstName: string(name?.shift()),
    lastName: string(name?.join(' ')),
    email: string(object?.contact_email),
    phone: string(object?.contact_phone),
  }
}
