import { Button } from '@velocity/ui'
import { useMemo } from 'react'

import { useContent } from '@ngb-frontend/content'
import { formatPhoneNumber } from '@ngb-frontend/shared/utils'

export const PhoneButton: React.FC = () => {
  const c = useContent()

  const phone = useMemo(
    () => formatPhoneNumber(c('steps.shared.phoneNumber')),
    [c],
  )

  return (
    <Button
      size="s"
      component="a"
      href={`tel:${phone}`}
      variant="primary-filled"
    >
      {phone}
    </Button>
  )
}
