import { isBefore, isSameDay } from 'date-fns'

import { dateToUTCDate } from '../date/dateToUTCDate'

import type {
  ValidationFunction,
  Validator,
  ValidationError,
} from '@ngb-frontend/shared/types'

const validationDefaults = {
  minMileageValue: 1,
  maxMileageValue: 9999999,
}

const compose =
  <T>(...validators: ReadonlyArray<Validator<T> | undefined>) =>
  (value: T) =>
    validators.reduce<ValidationError | undefined>(
      (error, validator) => error || validator?.(value),
      undefined,
    )

const apply =
  <T>(
    validator: ValidationFunction<T>,
    validationError: ValidationError,
  ): Validator<T> =>
  (value: T) =>
    validator(value) ? undefined : validationError

const required = <T>(value: T) => {
  if (Array.isArray(value)) return !!value.length
  if (typeof value === 'string') return !!value.trim()

  return !!value
}

const minLength =
  (length: number) =>
  <T>(value: T) =>
    Array.isArray(value) || typeof value === 'string'
      ? value.length >= length
      : false

const validDate = <T>(value: T) =>
  (value instanceof Date && !isNaN(value.getTime())) ||
  (typeof value === 'string' && !isNaN(Date.parse(value)))

const minValue =
  (minValue: number) =>
  <T>(value: T) =>
    Number(value) >= minValue

const maxValue =
  (minValue: number) =>
  <T>(value: T) =>
    Number(value) <= minValue

const minMaxValue =
  (min: number, max: number) =>
  <T>(value: T) =>
    minValue(min)(value) && maxValue(max)(value)

const isBeforeDate = (d: string, dToComp: string, strict = false) => {
  const date = new Date(d)
  const dateToCompare = new Date(dToComp)

  if (!strict && isSameDay(date, dateToCompare)) return true

  return isBefore(date, dateToCompare)
}

const isBeforeTime = (t: string, sToComp: string) => {
  return t <= sToComp
}

const isAfterTime = (t: string, sToComp: string) => {
  return t >= sToComp
}

const isDateNotBefore =
  (beforeDate: string, required?: boolean) => (value: string) => {
    if (!required && !value?.trim()) {
      return true
    }

    if (!validDate(beforeDate)) {
      throw new Error('The "beforeDate" parameter is not a valid date string')
    }

    return isBeforeDate(beforeDate, value)
  }

const isDateNotDisabled = (utcDisabledDates: number[]) => (value: string) => {
  return !utcDisabledDates.includes(dateToUTCDate(value))
}

const isTimeNotBefore =
  (beforeTime: string, required?: boolean) => (value: string) => {
    if (!required && !value?.trim()) {
      return true
    }
    return isBeforeTime(beforeTime, value)
  }

const isTimeNotAfter =
  (afterTime: string, required?: boolean) => (value: string) => {
    if (!required && !value?.trim()) {
      return true
    }
    return isAfterTime(afterTime, value)
  }

export const validation = {
  validationDefaults,
  compose,
  apply,
  required,
  minLength,
  validDate,
  minValue,
  maxValue,
  minMaxValue,
  isBeforeDate,
  isDateNotBefore,
  isDateNotDisabled,
  isTimeNotAfter,
  isTimeNotBefore,
}
