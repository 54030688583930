
      import API from "!../../../../../../../../nx/node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
      import domAPI from "!../../../../../../../../nx/node_modules/style-loader/dist/runtime/styleDomAPI.js";
      import insertFn from "!../../../../../../../../nx/node_modules/style-loader/dist/runtime/insertBySelector.js";
      import setAttributes from "!../../../../../../../../nx/node_modules/style-loader/dist/runtime/setAttributesWithoutAttributes.js";
      import insertStyleElement from "!../../../../../../../../nx/node_modules/style-loader/dist/runtime/insertStyleElement.js";
      import styleTagTransformFn from "!../../../../../../../../nx/node_modules/style-loader/dist/runtime/styleTagTransform.js";
      import content, * as namedExport from "!!../../../../../../../../nx/node_modules/css-loader/dist/cjs.js!./global.css";
      
      

var options = {};

options.styleTagTransform = styleTagTransformFn;
options.setAttributes = setAttributes;

      options.insert = insertFn.bind(null, "head");
    
options.domAPI = domAPI;
options.insertStyleElement = insertStyleElement;

var update = API(content, options);



export * from "!!../../../../../../../../nx/node_modules/css-loader/dist/cjs.js!./global.css";
       export default content && content.locals ? content.locals : undefined;
