import { sharedStaticConfig } from '@ngb-frontend/shared/mocks'

import type { SharedConfig } from '@ngb-frontend/shared/types'

export const getTestSharedConfig = (): SharedConfig => ({
  ...sharedStaticConfig,
  disableAuth: true,
  mockApi: true,
  features: {
    driverCourtesy: true,
    eligibilityCheck: false,
  },
  buildInfo: {
    commitSha: '',
    branch: '',
    timestamp: '',
    productName: 'Next Generation Booking',
  },
  azure: {
    azureAD: {
      clientId: '',
      tenantId: '',
    },
    azureEntraID: {
      clientId: '',
      tenantId: '',
    },
    azureB2C: {
      clientId: '',
      tenantId: '',
      authority: '',
      authorityEndpoint: '',
    },
  },
})
