export const splitDateTime = (dateTimeString?: string) => {
  if (!dateTimeString) return { date: '', time: '' }

  const [date, time] = dateTimeString?.split('T') || []
  const [hours, minutes] = time.split(':')

  return {
    date,
    time: `${hours}:${minutes}`,
  }
}
