import { ServiceType } from '@ngb-frontend/shared/types'

import { mockAddress } from './address'

import type {
  Supplier,
  SupplierRank,
  Suppliers,
} from '@ngb-frontend/shared/types'

const locations = [
  '52.3400062,4.823988',
  '52.3504078,4.9175331,17',
  '52.3366149,4.9087275',
  '52.3724308,4.8931382',
]

export const mockSupplier: Supplier = {
  id: '1234523452345',
  name: 'Van Poelgeest BMW Amsterdam',
  phone: '123233232',
  location: '52.3400062,4.823988',
  driving_distance: '0.4 km',
  driving_duration: '3 min',
  end_time: '16:00',
  ranking: 'P1',
  start_time: '08:30',
  steering_index: '100',
  working_hours: [],
  address: mockAddress,
  service_types: [ServiceType.Isp, ServiceType.Oem],
  on_sims: false,
}

export const mockSuppliers: Supplier[] = locations.map((location, idx) => ({
  ...mockSupplier,
  name: `${mockSupplier.name} ${idx + 1}`,
  ranking: `P${idx + 1}` as SupplierRank,
  id: `mock-supplier-${idx}`,
  driving_distance: `${idx * 1000} km`,
  driving_duration: `${idx * 10} min`,
  location,
  on_sims: idx % 2 === 0,
}))

export const mockSuppliersResponse: Required<Suppliers> = {
  suppliers: mockSuppliers,
}
