import { Text } from '@velocity/ui'
import React, { useMemo } from 'react'

import { useContent } from '@ngb-frontend/content'
import { ContactType, type RadioCardProps } from '@ngb-frontend/shared/types'

import { RadioCard } from '../RadioCard'

export type OtherContactRadioCardProps = Pick<
  RadioCardProps<ContactType>,
  'onChange' | 'checked'
>

// Opinionated wrapper
export const OtherContactRadioCard = (
  props: React.PropsWithChildren<OtherContactRadioCardProps>,
) => {
  const c = useContent()
  const title = useMemo(() => {
    return (
      <Text variant="300" bold margin={{ bottom: '02' }}>
        {c('fields.contact.otherContactTitle')}
      </Text>
    )
  }, [c])

  return (
    <RadioCard<ContactType>
      {...props}
      borderColor="neutral"
      hideCollapseIndicator={true}
      id={ContactType.Other}
      title={title}
      content={c('fields.contact.otherContactDescription')}
      name="other-radio-card"
    />
  )
}
