import { Headline } from '@velocity/ui'
import Image from 'next/image'
import React from 'react'

import { useBannerStyles } from './Banner.styled'
import defaultBanner from '../../public/images/step-banner-desktop.png'

import type { BannerProps } from '@ngb-frontend/shared/types'

export const BANNER_LOGOS = {
  default: defaultBanner,
}

export const Banner: React.FC<BannerProps> = ({ title, image }) => {
  const classes = useBannerStyles()

  return (
    <div className={classes.banner} data-e2e-component="step-layout-banner">
      <Image
        fill
        className={classes.backgroundImage}
        alt="banner"
        src={BANNER_LOGOS[image]}
      />
      <div className={classes.headlineContainer}>
        <div className={classes.headlineInner}>
          <Headline variant="500" className={classes.headline}>
            {title}
          </Headline>
        </div>
      </div>
    </div>
  )
}
