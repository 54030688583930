import { lazy, Suspense } from 'react'

import { FullScreenLoader } from '@ngb-frontend/shared/ui'

import { hasAdditionalInformationFormValues } from '../AdditionalInformationStep/AdditionalInformationStep'

import type {
  ExtraServiceStepPrerequisiteData,
  ServiceStepComponentProps,
  ServiceStepValues,
  StepComponentContainer,
} from '@ngb-frontend/shared/types'

const ServiceStepLazy = lazy(() => import('../ServiceStep/ServiceStep'))

export const ExtraServiceStepContainer: StepComponentContainer<
  ServiceStepValues,
  ExtraServiceStepPrerequisiteData,
  ServiceStepComponentProps
> = (props) => {
  return (
    <Suspense fallback={<FullScreenLoader />}>
      <ServiceStepLazy {...props} />
    </Suspense>
  )
}

ExtraServiceStepContainer.areStepPrerequisitesMet = (
  mergedFlowData,
): mergedFlowData is ExtraServiceStepPrerequisiteData =>
  hasAdditionalInformationFormValues(mergedFlowData)

ExtraServiceStepContainer.skip = (mergedFlowData) => ({
  enabled: !mergedFlowData.services?.includes('RegularMaintenance'),
  state: { extraServices: [], services: mergedFlowData.services },
})
