import React, { useCallback } from 'react'
import { Form } from 'react-final-form'

import { useContent } from '@ngb-frontend/content'
import { StepVariants } from '@ngb-frontend/shared/types'
import { AddressFields, FormFooter } from '@ngb-frontend/shared/ui'
import { setAddressFromPlace } from '@ngb-frontend/shared/utils'

import type {
  StepFormProps,
  OtherLocationFormValues,
} from '@ngb-frontend/shared/types'

export const OtherLocationForm: React.FC<
  StepFormProps<OtherLocationFormValues> & { stepVariant?: StepVariants }
> = ({ onSubmit, initialValues, stepVariant }) => {
  const c = useContent()
  const handleSubmit = useCallback(
    (values: OtherLocationFormValues) => onSubmit?.(values),
    [onSubmit],
  )

  const withPickup = stepVariant === StepVariants.OtherLocationWithPickup
  const initialVisible = !!Object.keys(initialValues?.address || {}).length

  return (
    <Form<OtherLocationFormValues>
      onSubmit={handleSubmit}
      initialValues={initialValues}
      mutators={{ setAddressFromPlace }}
    >
      {(formProps) => (
        <form
          onSubmit={formProps.handleSubmit}
          data-e2e-component="other-location-form"
        >
          <AddressFields
            required={withPickup}
            showFieldsOnInit={initialVisible}
            onSelect={formProps.form.mutators.setAddressFromPlace}
            hideFieldsStyle="display"
          />
          <FormFooter disabled={formProps.invalid}>
            {c('steps.shared.search')}
          </FormFooter>
        </form>
      )}
    </Form>
  )
}
