import {
  EndOfLeaseStepContainer,
  SummaryStepContainer as SummaryStepContainerEoLDCC,
} from '@ngb-frontend/features/end-of-lease-dcc'
import {
  MileageStepContainer,
  ServiceStepContainer,
  MalfunctionStepContainer,
  InspectionStepContainer,
  ExtraServiceStepContainer,
  DateStepContainer,
  AdditionalInformationStepContainer,
  ContactStepContainer,
  SelectServiceLocationStepContainer,
  SummaryStepContainer,
  ReplacementVehicleStepContainer,
  LocationStepContainer,
  OtherLocationStepContainer,
} from '@ngb-frontend/features/maintenance'
import {
  MaintenanceAndRepairStepContainer,
  SummaryStepContainer as SummaryStepContainerMnRDCC,
} from '@ngb-frontend/features/maintenance-dcc'
import { StepVariants } from '@ngb-frontend/shared/types'
import { ServiceLocationStepContainer } from '@ngb-frontend/shared/ui'

import type { Feature, Steps } from '@ngb-frontend/shared/types'

const cKeys = {
  locationTitle: 'steps.location.title',
  locationNavLabel: 'steps.location.navLabel',
}

export const EOL_DCC_STEPS: Steps<Feature.EoLDCC> = [
  {
    component: EndOfLeaseStepContainer,
    path: 'eol-dcc/end-of-lease',
    navLabel: 'steps.endOfLease.navLabel',
    title: 'steps.endOfLease.title',
  },
  {
    component: ServiceLocationStepContainer,
    path: 'eol-dcc/service-location',
    fullWidth: true,
    variant: {
      [StepVariants.ServiceLocation]: {
        navLabel: 'steps.selectServiceLocation.navLabel',
        title: 'steps.selectServiceLocation.title',
      },
      [StepVariants.ServiceLocationWithPickup]: {
        navLabel: 'steps.selectServiceLocationWithPickup.navLabel',
        title: 'steps.selectServiceLocationWithPickup.title',
      },
    },
  },
  {
    component: SummaryStepContainerEoLDCC,
    path: 'eol-dcc/summary',
    navLabel: 'steps.endOfLeaseSummary.navLabel',
    title: 'steps.endOfLeaseSummary.title',
    variant: {
      [StepVariants.Overview]: {
        routeToBack: true,
      },
    },
  },
]

export const MNR_DCC_STEPS: Steps<Feature.MnRDCC> = [
  {
    component: MaintenanceAndRepairStepContainer,
    path: 'dcc/maintenance-and-repair',
    navLabel: 'steps.maintenanceAndRepair.navLabel',
    title: 'steps.maintenanceAndRepair.title',
  },
  {
    component: ServiceLocationStepContainer,
    path: 'dcc/service-location',
    fullWidth: true,
    navLabel: cKeys.locationNavLabel,
    variant: {
      [StepVariants.ServiceLocation]: {
        navLabel: 'steps.selectServiceLocation.navLabel',
        title: 'steps.selectServiceLocation.title',
      },
      [StepVariants.ServiceLocationWithPickup]: {
        navLabel: 'steps.selectServiceLocationWithPickup.navLabel',
        title: 'steps.selectServiceLocationWithPickup.title',
      },
    },
  },
  {
    component: SummaryStepContainerMnRDCC,
    path: 'dcc/summary',
    navLabel: 'steps.summary.navLabel',
    title: 'steps.summary.title',
    variant: {
      [StepVariants.Overview]: {
        routeToBack: true,
      },
    },
  },
]

export const DRIVER_STEPS: Steps<Feature.MnR> = [
  {
    component: MileageStepContainer,
    path: 'mileage',
    navLabel: 'steps.mileage.navLabel',
    title: 'steps.mileage.title',
    banner: {
      title: 'steps.mileage.bannerTitle',
      image: 'default',
    },
  },
  {
    component: ServiceStepContainer,
    path: 'services',
    navLabel: 'steps.service.navLabel',
    title: 'steps.service.title',
    flowVariant: 'services',
  },
  {
    component: MalfunctionStepContainer,
    path: 'malfunctions',
    navLabel: 'steps.malfunction.navLabel',
    title: 'steps.malfunction.title',
  },
  {
    component: InspectionStepContainer,
    path: 'inspection',
    navLabel: 'steps.inspection.navLabel',
    title: 'steps.inspection.title',
  },
  {
    component: AdditionalInformationStepContainer,
    path: 'additional-information',
    navLabel: 'steps.additionalInformation.navLabel',
    title: 'steps.additionalInformation.title',
  },
  {
    component: ExtraServiceStepContainer,
    path: 'extra-services',
    navLabel: 'steps.extraService.navLabel',
    title: 'steps.extraService.title',
    flowVariant: 'extra-services',
  },
  {
    component: ReplacementVehicleStepContainer,
    path: 'replacement-vehicle',
    navLabel: 'steps.replacementVehicle.navLabel',
    title: 'steps.replacementVehicle.title',
  },
  {
    component: DateStepContainer,
    path: 'date',
    variant: {
      [StepVariants.Date]: {
        navLabel: 'steps.date.navLabel',
        title: 'steps.date.title',
      },
      [StepVariants.DateWithPickup]: {
        navLabel: 'steps.dateWithPickup.navLabel',
        title: 'steps.dateWithPickup.title',
      },
    },
  },
  {
    component: LocationStepContainer,
    path: 'location',
    variant: {
      [StepVariants.Location]: {
        navLabel: cKeys.locationNavLabel,
        title: cKeys.locationTitle,
      },
      [StepVariants.LocationWithPickup]: {
        navLabel: 'steps.locationWithPickup.navLabel',
        title: 'steps.locationWithPickup.title',
      },
    },
  },
  {
    component: OtherLocationStepContainer,
    path: 'address',
    variant: {
      [StepVariants.OtherLocation]: {
        navLabel: cKeys.locationNavLabel,
        title: cKeys.locationTitle,
      },
      [StepVariants.OtherLocationWithRPV]: {
        navLabel: cKeys.locationNavLabel,
        title: cKeys.locationTitle,
      },
      [StepVariants.OtherLocationWithPickup]: {
        navLabel: 'steps.otherLocationWithPickup.navLabel',
        title: 'steps.otherLocationWithPickup.title',
      },
    },
  },
  {
    component: SelectServiceLocationStepContainer,
    path: 'select-service-location',
    navLabel: cKeys.locationNavLabel,
    title: 'steps.selectServiceLocation.title',
  },
  {
    component: ContactStepContainer,
    path: 'contact',
    navLabel: 'steps.contact.navLabel',
    title: 'steps.contact.title',
  },
  {
    component: SummaryStepContainer,
    path: 'summary',
    navLabel: 'steps.summary.navLabel',
    title: 'steps.summary.title',
    variant: {
      [StepVariants.Overview]: {
        routeToBack: true,
      },
    },
  },
]
