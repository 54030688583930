import * as R from 'ramda'

import { hasSupplier } from '../typeguards/hasSupplier'

import type { ServiceLocationStepValues } from '@ngb-frontend/shared/types'

export const hasSelectServiceLocationStepValues = (
  x: unknown,
): x is ServiceLocationStepValues =>
  R.allPass([R.pipe(R.prop('supplier'), hasSupplier)])(x)
