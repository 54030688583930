/**
 * TODO: Find out if these links need to be localized
 * - some links need to be prefixed with the current locale
 * - slugs will also need to be adjusted for each locale
 * - not all links need localization so there needs to be differentiation
 */
type FooterLinksProps = {
  base: string
  target: string
  legal: FooterLink[]
}

type FooterLink = {
  label: string
  link: string
}

export const FOOTER_LINKS: FooterLinksProps = {
  base: 'https://www.leaseplan.com',
  target: '_blank',
  legal: [
    {
      label: 'cookiePolicy',
      link: 'cookiePolicyLink',
    },
    {
      label: 'termsAndConditions',
      link: 'termsAndConditionsLink',
    },
    {
      label: 'privacyStatement',
      link: 'privacyStatementLink',
    },
    {
      label: 'dataRights',
      link: 'dataRightsLink',
    },
    {
      label: 'complaintsCode',
      link: 'complaintsCodeLink',
    },
  ],
}
