import { createContext, useContext } from 'react'

import type { ClientConfig } from '@ngb-frontend/shared/types'

/**
 * A context with the application configuration (regardless the current user's experience).
 */
const AppConfigContext = createContext<ClientConfig | undefined>(undefined)

export const AppConfigServiceProvider = AppConfigContext.Provider

export const useAppConfig = (): ClientConfig => {
  const appConfig = useContext(AppConfigContext)
  if (!appConfig) {
    throw new Error(
      'No Context value found for `AppConfig`. Did you forget to wrap your application in the `AppConfigServiceProvider`?',
    )
  }

  return appConfig
}
