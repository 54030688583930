const noop = () => {
  return
}

export const setupGoogleMock = () => {
  /** Mock Google Maps JavaScript API ***/
  return {
    maps: {
      __gjsload__: noop,
      LatLng: noop,
      LatLngBounds: () => ({
        extend: noop,
      }),
    } as unknown as typeof google.maps,
  } as typeof google
}
