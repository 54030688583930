import { LocalstorageStore } from '@cj4/store-localstorage'
import { useCallback } from 'react'
import { useSWRConfig } from 'swr'

import { useAppConfig } from '@ngb-frontend/shared/context'

import type { LocalStorageKeys } from '@ngb-frontend/shared/types'

export const useCleanup = () => {
  const { cache } = useSWRConfig()
  const config = useAppConfig()

  const clearSWRCache = useCallback(() => {
    const keys = cache.keys()
    Array.from(keys).forEach((key) => cache.delete(key))
  }, [cache])

  const clearLocalStorage = useCallback(
    (clearConfig?: {
      include?: LocalStorageKeys[]
      exclude?: LocalStorageKeys[]
    }) => {
      const allKeys = Object.keys(config.localStorageKeys) as LocalStorageKeys[]
      const includeKeys = clearConfig?.include || allKeys
      const excludeKeys = clearConfig?.exclude || []

      includeKeys.forEach((key) => {
        if (excludeKeys.includes(key)) {
          return
        }
        const store = new LocalstorageStore(config.localStorageKeys[key])
        store.set(null)
      })
    },
    [config.localStorageKeys],
  )

  return {
    clearSWRCache,
    clearLocalStorage,
  }
}
