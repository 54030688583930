import { lazy, Suspense } from 'react'

import { FullScreenLoader } from '@ngb-frontend/shared/ui'

import type {
  MaintenanceAndRepairStepValues,
  StepComponentContainer,
} from '@ngb-frontend/shared/types'

const MaintenanceAndRepairStepLazy = lazy(
  () => import('./MaintenanceAndRepairStep'),
)

export const MaintenanceAndRepairStepContainer: StepComponentContainer<
  MaintenanceAndRepairStepValues
> = (props) => {
  return (
    <Suspense fallback={<FullScreenLoader />}>
      <MaintenanceAndRepairStepLazy {...props} />
    </Suspense>
  )
}

MaintenanceAndRepairStepContainer.areStepPrerequisitesMet = (
  _mergedFlowData,
): _mergedFlowData is {} => true
