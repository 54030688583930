import { makeStyles } from '@material-ui/core'
import { getTypographyCSS } from '@velocity/styling'

import type { Theme } from '@material-ui/core'
import type { TextVariant } from '@velocity/styling'

const sizeMap: { [key: string]: TextVariant } = {
  s: '200',
  m: '300',
  l: '400',
}

interface Props {
  size: 's' | 'm' | 'l'
}

export const useLinkInlineStyles = makeStyles<Theme, Props>(() => ({
  root: ({ size }: Props) => ({
    '&&': {
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      textDecoration: 'none',
      ...getTypographyCSS('text', sizeMap[size], 'bold'),
      '&&:hover': {
        background: 'none',
      },
    },
  }),
  startIcon: {
    marginRight: 'var(--vel-fixed-spacing-02)',
  },
  endIcon: {
    marginLeft: 'var(--vel-fixed-spacing-02)',
  },
}))
