import type {
  AdditionalInformationStepValues,
  ContactStepValues,
  InspectionStepValues,
  MalfunctionStepValues,
  MileageStepValues,
  DateStepValues,
  LocationStepValues,
  OtherLocationStepValues,
  ReplacementVehicleStepValues,
  SelectServiceLocationStepValues,
  ServiceStepValues,
  MaintenanceAndRepairStepValues,
  EndOfLeaseStepValues,
  ServiceLocationStepValues,
} from '.'

export enum Feature {
  MnR = 'maintenanceAndRepair',
  MnRDCC = 'maintenanceAndRepairDCC',
  EoLDCC = 'endOfLeaseDCC',
}

/**
 * kebab-case path entries to be matched by Flow's routing. Used also to separate
 * reusable steps/forms.
 
* NB: Keep the entries in flow order
 */
export enum MaintenanceAndRepair {
  Mileage = 'mileage',
  Service = 'services',
  Malfunction = 'malfunctions',
  Inspection = 'inspection',
  AdditionalInformation = 'additional-information',
  ExtraService = 'extra-services',
  Date = 'date',
  Location = 'location',
  OtherLocation = 'address',
  SelectServiceLocation = 'select-service-location',
  Contact = 'contact',
  ReplacementVehicle = 'replacement-vehicle',
  Summary = 'summary',
}

enum MnRDCC {
  maintenanceAndRepair = 'dcc/maintenance-and-repair',
  supplierSelect = 'dcc/service-location',
  summary = 'dcc/summary',
}

enum EoLDCC {
  endOfLease = 'eol-dcc/end-of-lease',
  supplierSelect = 'eol-dcc/service-location',
  summary = 'eol-dcc/summary',
}

export type FeaturePaths = {
  [Feature.MnR]: MaintenanceAndRepair
  [Feature.MnRDCC]: MnRDCC
  [Feature.EoLDCC]: EoLDCC
}

/**
 * Date and Location variants depend on whether Pickup is selected.
 * Other location variants depend on whether Pickup or RPV is selected.
 */
export enum StepVariants {
  Date = 'date',
  DateWithPickup = 'dateWithPickup',
  Location = 'location',
  LocationWithPickup = 'locationWithPickup',
  OtherLocation = 'otherLocation',
  OtherLocationWithPickup = 'otherLocationWithPickup',
  OtherLocationWithRPV = 'otherLocationWithRPV',
  ServiceLocation = 'selectServiceLocation',
  ServiceLocationWithPickup = 'selectServiceLocationWithPickup',
  Summary = 'summary',
  Overview = 'overview',
}

export type FeatureState = {
  [Feature.MnR]: MaintenanceFeatureStepData[]
  [Feature.MnRDCC]: MnRDCCFeatureStepData[]
  [Feature.EoLDCC]: EoLDCCFeatureStepData[]
}

export type MaintenanceFeatureStepData =
  | MileageStepValues
  | Pick<ServiceStepValues, 'services'>
  | Pick<ServiceStepValues, 'extraServices'>
  | InspectionStepValues
  | MalfunctionStepValues
  | AdditionalInformationStepValues
  | ReplacementVehicleStepValues
  | DateStepValues
  | LocationStepValues
  | OtherLocationStepValues
  | SelectServiceLocationStepValues
  | ContactStepValues

export type MnRDCCFeatureStepData =
  | MaintenanceAndRepairStepValues
  | ServiceLocationStepValues

export type EoLDCCFeatureStepData =
  | EndOfLeaseStepValues
  | ServiceLocationStepValues
