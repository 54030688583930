import {
  Feature,
  UserType,
  BookingServiceCode as SrvCodes,
  SystemCode,
} from '@ngb-frontend/shared/types'
import { countryCodeToOrg, systemCodeToOrg } from '@ngb-frontend/shared/utils'

import { MNR_DCC_STEPS, DRIVER_STEPS, EOL_DCC_STEPS } from '../steps'

import type { CountryCode, FlowConfig } from '@ngb-frontend/shared/types'

// Default to Driver flow
export const getFlowConfig = (systemCode?: SystemCode, service?: SrvCodes) =>
  config.find((f) => f.when(systemCode, service)) || config[0]

export const config = [
  {
    steps: DRIVER_STEPS,
    type: Feature.MnR,
    when: (systemCode, bookingCode) =>
      (systemCode === SystemCode.MYLP || systemCode === SystemCode.MYAY) &&
      bookingCode === SrvCodes.MnR,
  } as FlowConfig<Feature.MnR>,
  {
    steps: MNR_DCC_STEPS,
    type: Feature.MnRDCC,
    when: (systemCode, bookingCode) =>
      (systemCode === SystemCode.SFLP || systemCode === SystemCode.SFALD) &&
      bookingCode === SrvCodes.MnR,
  } as FlowConfig<Feature.MnRDCC>,
  {
    steps: EOL_DCC_STEPS,
    type: Feature.EoLDCC,
    when: (systemCode, bookingCode) =>
      (systemCode === SystemCode.SFLP || systemCode === SystemCode.SPLP) &&
      bookingCode === SrvCodes.EoL,
  } as FlowConfig<Feature.EoLDCC>,
]

export const isValidSystemCountry = (
  systemCode: SystemCode,
  countryCode: CountryCode,
) => {
  const organization = countryCodeToOrg[countryCode]

  // Ensure it is supported
  return !!(organization &&
  organization === systemCodeToOrg[systemCode as SystemCode]
    ? countryCode
    : null)
}

export const isValidSystemService = (user: UserType, srv: string) => {
  const serviceCodes = Object.values(SrvCodes)

  if (!serviceCodes.includes(srv as SrvCodes) || user === UserType.Unknown) {
    return false
  }

  if (
    (user === UserType.Driver && srv !== SrvCodes.MnR) ||
    (user === UserType.Broker && srv !== SrvCodes.EoL)
  ) {
    return false
  }

  return true
}
