import type { Address } from '@ngb-frontend/shared/types'

export const mockAddress: Required<Address> = {
  country: 'Netherlands',
  country_code: 'LPNL',
  city: 'Amsterdam',
  street: 'Johan Huizingalaan',
  house_number: '400',
  house_number_suffix: 'B',
  postcode: '1066 JS',
  county: 'Noord Holland',
}

export const mockAddressAlt: Required<Address> = {
  country: 'Netherlands',
  country_code: 'LPNL',
  city: 'Amsterdam',
  street: 'Stephensonstraat',
  house_number: '20',
  house_number_suffix: 'C',
  postcode: '1097 BB',
  county: 'Noord Holland',
}
