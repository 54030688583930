import { Grid } from '@velocity/ui'
import React, { useCallback } from 'react'
import { Form } from 'react-final-form'

import { useContent } from '@ngb-frontend/content'
import { FormFooter, InspectionsField } from '@ngb-frontend/shared/ui'
import { validation as v } from '@ngb-frontend/shared/utils'

import { useInspectionFormStyles } from './InspectionForm.styled'

import type {
  InspectionFormProps,
  InspectionFormValues,
} from '@ngb-frontend/shared/types'

export const InspectionForm: React.FC<InspectionFormProps> = ({
  onSubmit,
  initialValues,
  inspections,
}) => {
  const c = useContent()
  const classes = useInspectionFormStyles()

  const handleSubmit = React.useCallback(
    (values: InspectionFormValues) => {
      onSubmit?.(values)
    },
    [onSubmit],
  )

  const validator = useCallback(
    (values: InspectionFormValues) => ({
      mot: v.apply(v.required, {
        message: c('errors.motRequired'),
        type: 'errorType',
      })(values.inspection),
    }),
    [c],
  )

  return (
    <Form<InspectionFormValues>
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validate={validator}
    >
      {(formProps) => (
        <form
          onSubmit={formProps.handleSubmit}
          data-e2e-component="inspection-form"
          className={classes.root}
        >
          <Grid margin={{ bottom: '07' }}>
            <Grid.Item LG={8}>
              <InspectionsField
                row
                initialValues={initialValues?.inspection}
                inspections={inspections}
                dirty={
                  formProps.dirtyFields.inspection || formProps.submitFailed
                }
                error={formProps.errors?.mot}
              />
            </Grid.Item>
          </Grid>
          <FormFooter />
        </form>
      )}
    </Form>
  )
}
