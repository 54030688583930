import { makeStyles } from '@material-ui/core'

export const useInspectionFormStyles = makeStyles(() => ({
  root: {
    '& #inspection-input_label': {
      display: 'none',
    },
  },
  radioCardGroup: {
    '& > div': {
      width: '100%',
      '& label': {
        width: '100%',
        '& span > div:first-child': {
          width: '200px',
          color: 'var(--vel-color-secondary-500)',
        },
      },
      '& ul': {
        marginLeft: 'var(--vel-fixed-spacing-01)',
      },
    },
  },
}))
