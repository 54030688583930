import { string } from '../string/string'

export const hasComplexTruthyFields = (
  fields: Record<string, boolean> = {},
  searchString: string,
) =>
  Object.entries(fields).some(
    ([key, val]) =>
      string(key).toLowerCase().includes(string(searchString).toLowerCase()) &&
      val,
  )

export const hasTruthyFields = (fields: Record<string, boolean> = {}) =>
  Object.values(fields).find(Boolean)
